.TagList {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: visibility 0s ease 0.3s;
  z-index: 25;
  visibility: hidden;
}
.TagList.isActive {
  visibility: visible;
  transition: visibility 0.3s ease;
}
.TagList.isActive .TagList-overlay {
  opacity: 1;
}
.TagList.isActive .TagList-content {
  transform: translateY(0);
}
.TagList.isActive .TagList-close {
  transform: scaleX(1) scaleY(1) rotateZ(0);
}
.TagList-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(15, 12, 26, 0.4);
  transition: opacity 0.3s ease;
  z-index: 3;
}
.TagList-close {
  position: absolute;
  height: 36px;
  width: 36px;
  top: -60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  border: 1px solid #edeef2;
  stroke: #e6102d;
  transition: transform 0.3s ease;
  transform: scaleX(0) scaleY(0) rotateZ(90deg);
  border-radius: 50%;
  z-index: 4;
}
.TagList-close svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 14px;
  width: 14px;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.TagList-content {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 7px;
  background: #fff;
  transition: transform 0.3s ease;
  transform: translateY(100%);
  z-index: 4;
  max-height: calc(100vh - 162px);
}
.TagList-content .StyleTag {
  margin-bottom: 15px;
  margin-right: 15px;
}
.TagList-list {
  padding: 20px 15px 10px;
  overflow-y: auto;
}
.TagList-submit {
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
