.Principles {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 51px;
  background: #fafafc;
}
.Principles-backgroundImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-mask-image: linear-gradient(black, transparent);
          mask-image: linear-gradient(black, transparent);
}
.Principles-menuTop {
  width: 100%;
  z-index: 1;
}
.Principles-contentWrapper {
  padding: 15px;
}
.Principles-header {
  color: #1c1630;
  width: 100%;
  padding-top: 55px;
  z-index: 1;
}
.Principles-subtitle {
  display: flex;
  margin-bottom: 4px;
}
.Principles-subtitleStroke {
  height: 19px;
  width: 109px;
  margin-right: 11px;
  border-bottom: 1px solid #e6102d;
}
.Principles-subtitleText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2.75px;
  text-transform: uppercase;
  color: #e6102d;
}
.Principles-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 38px;
}
.Principles-text {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0;
}
.Principles-text-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 6px;
}
.Principles-text p {
  margin-bottom: 25px;
}
.Principles-quote {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  color: #1c1630;
  position: relative;
  width: 100%;
  margin-top: 29px;
  padding: 44px 15px;
  background: #fff;
}
.Principles-quote:after {
  content: '';
  position: absolute;
  height: 29px;
  width: 29px;
  top: 0;
  right: 0;
  border-bottom: 29px solid #fff;
  border-right: 29px solid #fafafc;
}
.Principles-afterQuote {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #1c1630;
  width: 100%;
  margin-top: 35px;
}
.Principles-slider {
  margin-top: 37px;
}
.Principles-bottom {
  margin-top: 40px;
}
.Principles-icon {
  display: flex;
  position: relative;
  margin-bottom: 39px;
  padding-top: 16px;
}
.Principles-icon svg {
  height: 60px;
  width: 60px;
  top: 0;
  left: 0;
}
.Principles-iconSvg {
  margin-right: 35px;
}
.Principles-iconText {
  font-size: 14px;
  line-height: 20px;
  color: #78797a;
}
.Principles-finalText {
  margin-top: 65px;
}
.Principles-value {
  padding-top: 32px;
}
.Principles-value-slides {
  font-size: 0;
  margin-top: 23px;
}
.Principles-value-slides-item {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
}
.Principles-value-slides-item:nth-child(2n) {
  margin-right: 0;
}
.Principles-value-slides-item:hover .Principles-value-slides-item-image {
  transform: rotateY(180deg);
}
.Principles-value-slides-item:hover .Principles-value-slides-item-description {
  transform: rotateY(0deg);
}
.Principles-value-slides-item-image {
  background-size: cover;
  background-position: center center;
}
.Principles-value-slides-item-image:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.Principles-value-slides-item-image-title {
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 20px;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  font-weight: 500;
}
.Principles-value-slides-item-description {
  transform: rotateY(-180deg);
  box-sizing: border-box;
  border: 1px solid #e6102d;
  padding: 14px 20px;
}
.Principles-value-slides-item-image,
.Principles-value-slides-item-description {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.Principles-value-slides-item-title {
  font-size: 18px;
  line-height: 24px;
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
}
.Principles-value-slides-item-text {
  font-size: 12px;
  line-height: 16px;
}
