.TagListMedia.isActive {
  visibility: visible;
  transition: visibility 0.3s ease;
}
.TagListMedia.isActive .TagListMedia-overlay {
  opacity: 1;
}
.TagListMedia.isActive .TagListMedia-content {
  transform: translateY(0);
}
.TagListMedia.isActive .TagListMedia-close {
  transform: scaleX(1) scaleY(1) rotateZ(0);
}
.TagListMedia-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(15, 12, 26, 0.4);
  transition: opacity 0.3s ease;
  z-index: 3;
}
.TagListMedia-close {
  position: absolute;
  height: 36px;
  width: 36px;
  bottom: 347px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  border: 1px solid #edeef2;
  stroke: #e6102d;
  transition: transform 0.3s ease;
  transform: scaleX(0) scaleY(0) rotateZ(90deg);
  border-radius: 50%;
  z-index: 4;
}
.TagListMedia-close svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 14px;
  width: 14px;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.TagListMedia .StyleTag {
  margin-bottom: 15px;
  margin-right: 15px;
}
.TagListMedia-list {
  padding: 15px 0 0 15px;
  white-space: nowrap;
}
.TagListMedia-submit {
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
