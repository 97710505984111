.Post {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.7s ease;
  z-index: 22;
}
.Post--toAnimate {
  position: fixed;
  transform: translate3d(0, 100vh, 0);
}
.Post-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
}
.Post-header {
  position: relative;
}
.Post-img {
  background: #fff;
}
.Post-img img {
  display: block;
  width: 100%;
}
.Post-shareButton {
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: -98px;
  z-index: 10;
}
.Post-body {
  position: relative;
}
.Post-body:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}
.Post-content {
  position: relative;
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-bottom: 42px;
  background: #fff;
  z-index: 2;
}
.Post-meta {
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.Post-badge {
  font-size: 0;
  margin-right: 8px;
}
.Post-date {
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #616166;
  margin-right: 16px;
}
.Post-externalLink {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Post-externalLink svg {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 4px;
  fill: #e6102d;
}
.Post-externalLink span {
  position: relative;
}
.Post-externalLink span:after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 1px;
  right: 1px;
  border-bottom: 4px solid rgba(230, 16, 45, 0.3);
  transition: opacity 0.3s ease;
}
.Post-externalLink:hover:after,
.Post-externalLink:focus:after {
  opacity: 0;
}
.Post-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 35px;
  color: #1c1630;
  position: relative;
  margin-top: 18px;
  margin-bottom: 17px;
  padding-bottom: 22px;
  padding-left: 15px;
  padding-right: 15px;
}
.Post-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #ebebf5;
}
.Post-text {
  padding-left: 15px;
}
.Post-text > *:first-child {
  margin-top: 0;
}
.Post-text p {
  font-size: 18px;
  line-height: 22px;
  color: #1c1630;
  margin-top: 32px;
}
.Post-text p a {
  color: #e6102d;
  text-decoration: underline;
}
html.isNotTouch .Post-text p a:hover {
  text-decoration: none;
}
.Post-text .Slider {
  margin-top: 11px;
}
.Post-text .Slider + * {
  margin-top: 44px;
}
.Post-text figure {
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.Post-text figure .imageContainer {
  position: relative;
}
.Post-text figure img {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Post-text figure figcaption {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
}
.Post-video {
  position: relative;
}
.Post-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Post-pdf {
  display: block;
  background: #edeef2;
  padding: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.Post-pdf table td {
  vertical-align: bottom;
}
.Post-pdf table td:first-child {
  width: 100%;
  padding: 5px 15px 5px 5px;
  vertical-align: middle;
}
.Post-pdf svg {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 68px;
  transition: fill 0.3s;
}
.Post-pdf svg.SvgIcon--pdf-download-desktop {
  display: none;
}
.Post-bodyFooter {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 30px;
  padding: 0 15px;
}
.Post-viewCount {
  margin-left: 2px;
}
.Post-share {
  display: flex;
  position: relative;
  align-items: stretch;
  height: 50px;
  margin-right: 15px;
  cursor: pointer;
  border-radius: 5px;
}
.Post-share:last-child {
  margin-right: 0;
}
.Post-share--tg {
  background: #37ade9;
}
.Post-share--tg svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 26px;
  width: 26px;
}
.Post-share--vk {
  background: #07f;
}
.Post-share--vk svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 26px;
  width: 26px;
}
.Post-share--fb {
  background: #3c5998;
}
.Post-share--fb svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 20px;
  width: 11px;
}
.Post-share--tw {
  background: #34a6de;
}
.Post-share--tw svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 20px;
  width: 22px;
}
.Post-share:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.2);
  transition: opacity 0.3s ease;
  border-radius: 5px;
}
.Post-share:hover:after,
.Post-share:focus:after {
  opacity: 1;
}
.Post-shareIcon {
  position: relative;
  flex-shrink: 0;
  width: 50px;
  fill: #fff;
}
.Post-footer {
  background: #0f0c1a;
}
.Post-footer + img {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
.Post-similar {
  padding-top: 36px;
}
.Post-similarHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: #fff;
  margin-bottom: 40px;
  margin-left: -3px;
  padding-left: 18px;
  padding-right: 15px;
}
.Post-similarList {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.Post-similarListInner {
  display: inline-flex;
  padding-left: 15px;
}
.Post-tags {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-bottom: 13px;
  padding-left: 15px;
}
.Post-tags .StyleTag {
  margin-bottom: 15px;
  margin-right: 15px;
}
.Post-tags + .Post-bodyFooter {
  margin-top: 0;
}
.Post-subscribe {
  padding-top: 21px;
  padding-bottom: 25px;
}
.Post-subscribeText {
  font-size: 16px;
  letter-spacing: 0;
  color: #fff;
}
