.MenuTop {
  display: flex;
  justify-content: space-around;
  height: 100%;
  padding: 0 15px;
  background: #fff;
}
.MenuTop-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 45px;
  color: #1c1630;
  display: block;
  transition: color 0.3s ease;
}
.MenuTop-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.MenuTop-item.isActive {
  color: #e6102d;
}
.MenuTop-item:first-of-type {
  transform: translateX(2px);
}
.MenuTop--about,
.MenuTop--history,
.MenuTop--principles {
  border-bottom: 1px solid #ebebf6;
}
.MenuTop--about .MenuTop-item,
.MenuTop--history .MenuTop-item,
.MenuTop--principles .MenuTop-item {
  color: #0f0c1a;
}
.MenuTop--about .MenuTop-item:hover,
.MenuTop--history .MenuTop-item:hover,
.MenuTop--principles .MenuTop-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.MenuTop--about .MenuTop-item.isActive,
.MenuTop--history .MenuTop-item.isActive,
.MenuTop--principles .MenuTop-item.isActive {
  color: #e6102d;
}
