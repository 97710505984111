.Subscribe {
  font-size: 0;
  display: inline-block;
  position: relative;
}
.Subscribe-title {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.Subscribe-email {
  font-size: 18px;
  display: inline-block;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: none;
  transition: color 0.3s ease, border 0.3s ease;
  vertical-align: top;
  border-radius: 2px 0 0 2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.Subscribe-email:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.Subscribe-email:focus::-webkit-input-placeholder {
  color: transparent;
  transition: color 0.3s ease;
}
.Subscribe-email:focus::-ms-input-placeholder {
  color: transparent;
  transition: color 0.3s ease;
}
.Subscribe-email:focus::placeholder {
  color: transparent;
  transition: color 0.3s ease;
}
.Subscribe-email::-webkit-input-placeholder {
  color: silver;
  transition: color 0.3s ease;
}
.Subscribe-email::-ms-input-placeholder {
  color: silver;
  transition: color 0.3s ease;
}
.Subscribe-email::placeholder {
  color: silver;
  transition: color 0.3s ease;
}
.Subscribe-email.submitted {
  transition: color 0.3s ease;
}
.Subscribe-email.submitted ~ .Subscribe-message {
  font-size: 12px;
  color: #f7f8fc;
  display: none;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.Subscribe-email.wrongEmail {
  border: 1px solid #e61010;
  transition: color 0.3s ease, border 0.3s ease;
}
.Subscribe-email.wrongEmail ~ .Subscribe-message {
  font-size: 12px;
  color: #e61010;
  display: none;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.Subscribe-email.alreadySubscribed {
  color: transparent;
}
.Subscribe-email:disabled {
  color: black;
}
.Subscribe-submit {
  font-size: 18px;
  font-weight: bold;
  color: #f7f8fc;
  display: inline-block;
  background-color: #e6102d;
  border: 1px solid transparent;
  border: none;
  outline: none;
  transition: color 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  border-radius: 0 2px 2px 0;
}
.Subscribe-submit:hover,
.Subscribe-submit:focus {
  background-color: #cc001b;
  transition: background-color 0.3s ease;
}
.Subscribe-submit:enabled {
  color: white;
  transition: color 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}
.Subscribe-submit:enabled:hover {
  cursor: pointer;
}
.Subscribe-submit:focus {
  outline: none;
}
.Subscribe-submit.isActive {
  background-color: #0f0c1a;
  transition: background-color 0.3s ease;
}
.Subscribe--post {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
}
.Subscribe--post .Subscribe-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 4px;
  margin-right: 0;
}
.Subscribe--post .Subscribe-input {
  display: flex;
  margin-top: 15px;
}
.Subscribe--post .Subscribe-input-inner {
  flex: 1 1;
}
.Subscribe--post .Subscribe-email {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 15px;
  width: 100%;
  height: 50px;
  padding-top: 5px;
  padding-left: 12px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.Subscribe--post .Subscribe-email::-webkit-input-placeholder {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 15px;
  color: #7a7a7a;
}
.Subscribe--post .Subscribe-email::-ms-input-placeholder {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 15px;
  color: #7a7a7a;
}
.Subscribe--post .Subscribe-email::placeholder {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 15px;
  color: #7a7a7a;
}
.Subscribe--post .Subscribe-submit {
  position: relative;
  flex-shrink: 0;
  height: 50px;
  width: 51px;
  border: 1px solid #cc001b;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.Subscribe--post .Subscribe-submit svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 12px;
  width: 20px;
}
.Subscribe--post .Subscribe-message {
  position: absolute;
  bottom: -20px !important;
  left: 15px !important;
}
.Subscribe--footer .Subscribe-input {
  position: relative;
  width: 100%;
  padding-top: 36px;
}
.Subscribe--footer .Subscribe-input-inner {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 72px;
}
.Subscribe--footer .Subscribe-input-inner input {
  width: 100%;
}
.Subscribe--footer .Subscribe-submit {
  position: absolute !important;
  right: 0;
  top: 0;
}
