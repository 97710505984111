.Index {
  background: #fafafc;
}
.Index-slider {
  background: #fafafc;
}
.Index-primaryPosts {
  font-size: 0;
  width: 100%;
  margin-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
.Index-primaryPost {
  width: 100%;
  height: 352px;
  margin-bottom: 15px;
}
.Index-secondaryPosts {
  width: 100%;
  margin-top: 24px;
  padding-top: 11px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
}
.Index-secondaryHeader {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #1c1630;
}
.Index-postItem {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 13px;
  padding-bottom: 19px;
  border-bottom: 1px solid #edeef2;
}
.Index-postItem:hover .Index-postTitle {
  color: #e6102d;
}
.Index-postTitle {
  font-size: 21px;
  line-height: 32px;
  color: #1c1630;
  transition: color 0.3s ease;
}
.Index-postMeta {
  display: flex;
  margin-top: 22px;
}
.Index-postDate {
  font-size: 11px;
  line-height: 18px;
  letter-spacing: .85px;
  text-transform: uppercase;
  color: #919199;
  height: 16px;
  vertical-align: top;
  margin-top: 4px;
  margin-right: 15px;
}
.Index-about {
  padding-top: 15px;
  padding-bottom: 24px;
  padding-left: 8px;
  padding-right: 8px;
  background: #fff;
}
.Index-aboutCard {
  display: block;
  position: relative;
  height: 296px;
  margin-bottom: 16px;
}
.Index-aboutCard:last-child {
  margin-bottom: 0;
}
.Index-aboutImg {
  height: 100%;
}
.Index-aboutImg img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Index-aboutCaption {
  position: absolute;
  width: 312px;
  bottom: 0;
  left: 0;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  background: #fff;
}
.Index-aboutCategory {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-bottom: 8px;
}
.Index-aboutTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #1c1630;
  margin-left: -1px;
}
.Index-aboutButton {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 16px;
  right: 16px;
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 50%;
}
.Index-aboutButton svg {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  stroke: #979797;
  pointer-events: none;
}
.IndexPage .Content {
  background: #fff;
}
