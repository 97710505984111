.Brand {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  transition: transform .6s ease;
}
.Brand.isExiting {
  transform: scale(0.9);
}
.Brand.beforeEnter {
  transform: scale(0.9);
}
.Brand-content {
  margin-top: 50px;
  transition: height .3s ease;
  transition-delay: .3s;
}
.Brand-media {
  position: relative;
  height: calc(90vh - 50px);
}
.Brand-img .BrandImage {
  position: absolute;
  height: calc(100% - 200px);
  width: 100%;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 0;
}
.Brand-img .BrandImage-item {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.Brand-img .BrandImage .owl-stage-outer,
.Brand-img .BrandImage .owl-stage,
.Brand-img .BrandImage .owl-item {
  height: 100%;
}
.Brand-img .BrandImage .owl-dots {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.Brand-img .BrandImage .owl-dots button.owl-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  background: #d8d8d8;
  margin: 0 10px;
  position: relative;
}
.Brand-img .BrandImage .owl-dots button.owl-dot:before {
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
.Brand-img .BrandImage .owl-dots button.owl-dot.active {
  background-color: #e6102d;
}
.Brand-img .BrandImage .owl-dots button.owl-dot:focus {
  outline: none;
}
.Brand-img .BrandImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
}
.Brand-img .BrandImage.beforeEnter {
  opacity: 0;
  transform: translateX(100%);
}
.Brand-arrowLink {
  position: absolute;
  height: 26px;
  width: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: none;
  border: none;
  stroke: rgba(145, 145, 153, 0.4);
  transition: stroke 0.3s ease;
  cursor: pointer;
  z-index: 1;
}
.Brand-arrow--prev .Brand-arrowLink {
  left: 38px;
}
.Brand-arrow--next .Brand-arrowLink {
  right: 38px;
}
.Brand-arrowLink:hover,
.Brand-arrowLink:focus {
  stroke: #616166;
}
.Brand-arrowLink:hover + .Brand-arrowContent,
.Brand-arrowLink:focus + .Brand-arrowContent {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.Brand-arrowIcon {
  position: absolute;
  height: 100%;
  width: 13px;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.Brand-arrowIcon:after {
  content: ' ';
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
}
.Brand-arrowIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.Brand-arrow--next .Brand-arrowIcon {
  right: 0;
  transform: scale(-1);
}
.Brand-arrow--prev .Brand-arrowIcon {
  left: 0;
}
.Brand-arrowContent {
  display: flex;
  visibility: hidden;
  position: absolute;
  align-items: flex-start;
  max-width: 300px;
  min-height: 70px;
  top: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  opacity: 0;
  background: #fff;
  transition: opacity 0.3s ease, visibility 0.3s linear 0.3s;
  transform: translateY(-50%);
  border-radius: 2px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.Brand-arrow--prev .Brand-arrowContent {
  left: 24px;
  padding-left: 61px;
  padding-right: 6px;
}
.Brand-arrow--prev .Brand-arrowContent .Brand-arrowImg img {
  margin-left: 9px;
  margin-right: 0;
}
.Brand-arrow--next .Brand-arrowContent {
  right: 24px;
  padding-left: 6px;
  padding-right: 61px;
}
.Brand-arrowImg {
  flex-shrink: 0;
  align-self: center;
  height: 50px;
  width: 36px;
  margin-right: 9px;
}
.Brand-arrowImg img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Brand-arrowText {
  flex-shrink: 0;
  max-width: 150px;
  margin-top: 5px;
}
.Brand-arrowTitle {
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  white-space: normal;
  color: #1c1630;
  display: block;
  margin-bottom: 1px;
}
.Brand-arrowSubtitle {
  font-size: 12px;
  line-height: 20px;
  white-space: normal;
  color: #7a7a7a;
  display: block;
  height: 20px;
}
.Brand-warning {
  position: absolute;
  height: 5vh;
  width: calc(100% - 48px);
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: .29;
  fill: #ccd6dd;
}
.Brand-warning svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Brand-description {
  position: relative;
  padding: 0 15px;
  overflow: hidden;
  background: #fff;
  border-top: 1px solid #e9eaf3;
}
.Brand-descriptionInner {
  position: relative;
  padding-top: 46px;
}
.Brand-descriptionInner.isExiting {
  position: absolute;
  top: 0;
  left: 24px;
  right: 24px;
}
.Brand-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  color: #1c1630;
}
.Brand-link {
  margin-top: 11px;
  margin-bottom: 29px;
}
.Brand-social {
  position: absolute;
  top: 15px;
  right: 0;
}
.Brand-styles {
  overflow: auto;
  margin: 29px -15px 0;
}
.Brand-stylesInner {
  padding: 0 15px 15px;
  white-space: nowrap;
  font-size: 0;
}
.Brand-stylesInner .StyleTag {
  display: inline-block;
  margin-right: 12px;
}
.Brand-stylesInner .StyleTag:last-child {
  margin-right: 15px;
}
.Brand-factList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1px;
}
.Brand-fact {
  position: relative;
  padding-top: 22px;
  padding-bottom: 14px;
}
.Brand-fact:nth-child(2n) {
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 3 + 2 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(2n) {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 3 + 2 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact:nth-child(2n + 1) {
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 3 + 2 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(2n + 1) {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 3 + 2 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact:nth-child(2n + 1):after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #e9eaf3;
  width: calc(((100% - (2 - 1) * 48px - 1 * 48px - 0 * 48px) / 2 * 4 + 3 * 48px + 0 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .Brand-fact:nth-child(2n + 1):after {
    width: calc(((100% - (2 - 1) * 32px - 1 * 32px - 0 * 2 * 48px) / 2 * 4 + 3 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact--package {
  width: calc(((100% - (6 - 1) * 48px - 0 * 48px - 0 * 48px) / 6 * 6 + 5 * 48px + 0 * 48px + 0 * 48px / 2) + 0px) !important;
  padding-bottom: 0;
}
@media (max-width: 1199px) {
  .Brand-fact--package {
    width: calc(((100% - (6 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 6 * 6 + 5 * 32px + 0 * 32px + 0 * 48px) + 0px);
  }
}
.Brand-fact--package:after {
  display: none !important;
}
.Brand-factLabel {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
}
.Brand-factTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
  margin-top: 1px;
}
.Brand-factSubtitle {
  font-size: 12px;
  color: #94949d;
}
.Brand-packageList {
  white-space: nowrap;
  overflow: auto;
  padding: 3px 15px 19px;
  margin: 0 -15px;
  border-bottom: 1px solid #e9eaf3;
}
.Brand-package {
  display: inline-block;
  line-height: 20px;
  height: 24px;
  padding-left: 7px;
  padding-right: 8px;
  border: 1px solid #edeef2;
  border-radius: 2px;
}
.Brand-package:not(:last-child) {
  margin-right: 12px;
}
.Brand-packageTitle {
  font-size: 12px;
  color: #1c1630;
  margin-right: 2px;
}
.Brand-packageVolume {
  font-size: 12px;
  color: #ccd6dd;
}
.Brand-text {
  font-size: 16px;
  line-height: 25px;
  color: #1c1630;
  padding-top: 26px;
  padding-bottom: 28px;
  border-bottom: 1px solid #e9eaf3;
}
.Brand-text strong {
  font-weight: 500;
}
.Brand-text a {
  text-decoration: underline;
}
.Brand-text a:hover,
.Brand-text a:focus {
  text-decoration: none;
}
.Brand-buttonText {
  display: none;
  position: relative;
  height: 21px;
  width: 21px;
  top: -5px;
  margin-left: 8px;
  background: #1c1630;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}
.Brand-buttonText svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 3px;
  width: 13px;
  fill: #fff;
}
.Brand-snackSection {
  padding-top: 22px;
  padding-bottom: 24px;
}
.Brand-snackHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  color: #1c1630;
}
.Brand-snackList {
  font-size: 0;
  margin-top: 28px;
}
.Brand-snack {
  position: relative;
  width: 100%;
  margin-bottom: 18px;
}
.Brand-snack:before {
  content: "";
  position: absolute;
  left: 56px;
  top: -9px;
  right: 0;
  border-bottom: 1px solid #e9eaf3;
}
.Brand-snack:first-child:before {
  display: none;
}
.Brand-snackImg {
  display: inline-block;
  width: 40px;
  vertical-align: top;
  margin-right: 16px;
}
.Brand-snackImgInner {
  position: relative;
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}
.Brand-snackImgInner svg {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  fill: #e6102d;
}
.Brand-snackContent {
  display: inline-block;
  vertical-align: top;
}
.Brand-snackTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #1c1630;
  margin-bottom: -3px;
}
.Brand-awardsSection {
  padding-top: 22px;
  padding-bottom: 24px;
}
.Brand-awardsHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  color: #1c1630;
}
.Brand-awardsList {
  font-size: 0;
  margin-top: 28px;
}
.Brand-award {
  position: relative;
  width: 100%;
  margin-bottom: 18px;
}
.Brand-award:before {
  content: "";
  position: absolute;
  left: 56px;
  top: -9px;
  right: 0;
  border-bottom: 1px solid #e9eaf3;
}
.Brand-award:first-child:before {
  display: none;
}
.Brand-awardImg {
  display: inline-block;
  width: 40px;
  vertical-align: top;
  margin-right: 16px;
}
.Brand-awardImgInner {
  position: relative;
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}
.Brand-awardImgInner svg {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  fill: #e6102d;
}
.Brand-awardContent {
  padding-top: 2px;
  display: inline-block;
  vertical-align: top;
}
.Brand-awardTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #1c1630;
  margin-bottom: -3px;
}
.Brand-awardSubtitle {
  font-size: 12px;
  line-height: 20px;
  color: #919199;
}
.Brand-locations {
  height: 400px;
  background: #12151b;
}
.Brand-also {
  background: #ffffff;
}
.Brand-alsoHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  width: 250px;
  margin: 40px auto 28px;
  text-align: center;
}
.Brand-alsoGrid {
  background: #fff;
  white-space: nowrap;
  overflow: auto;
  border-top: 1px solid #edeef2;
}
.Brand-alsoGrid-item {
  display: inline-block;
  position: relative;
  height: 345px;
  width: 278px;
  border-left: 1px solid #edeef2;
}
.Brand-alsoGrid-item:first-child {
  border-left: none;
}
.Brand-alsoGrid-itemTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: .1px;
  color: #2e2e33;
  display: block;
  margin-bottom: 4px;
  z-index: 2;
}
.Brand-alsoGrid-itemStyle {
  font-size: 12px;
  color: #7a7a7a;
  display: block;
  z-index: 2;
}
.Brand-alsoGrid-itemContent {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.Brand-alsoGrid-itemImgContainer {
  position: relative;
  flex: 1 1;
  max-height: calc(100% - 58px);
}
.Brand-alsoGrid-itemImg {
  display: block;
  position: absolute;
  height: 90%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-bottom: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
}
.Brand-alsoGrid-itemBadge {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;
  position: absolute;
  height: 21px;
  top: 20px;
  right: 10px;
  padding: 0 5px;
  background: #ccd6dd;
  border-radius: 2px;
}
