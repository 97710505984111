.LocationsPopup {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: #12151b;
  transition: transform 0.7s ease;
  z-index: 22;
}
.LocationsPopup--toAnimate {
  position: fixed;
  transform: translate3d(0, 100vh, 0);
}
.LocationsPopup-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
}
.LocationsPopup-content {
  height: 100%;
}
.LocationsPopup-header {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  color: #1c1630;
  position: absolute;
  top: 0;
  left: 0;
  right: 50px;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  overflow: hidden;
  background: #fff;
  z-index: 2;
}
.LocationsPopup-categories {
  display: flex;
  position: absolute;
  top: 75px;
  left: 68px;
  right: 68px;
  background: #fff;
  border-radius: 2px;
  overflow: hidden;
  z-index: 2;
}
.LocationsPopup-category {
  display: flex;
  position: relative;
  height: 40px;
  width: 50%;
}
.LocationsPopup-category input {
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.LocationsPopup-category input:checked + label {
  background: #e6102d;
  color: #fff;
}
.LocationsPopup-category label {
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #919199;
  transition: color 0.3s ease;
  cursor: pointer;
}
.LocationsPopup-category label:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  opacity: 0;
  border-bottom: 4px solid #e6102d;
  transition: opacity 0.3s ease;
}
.LocationsPopup-category label:hover,
.LocationsPopup-category label:focus {
  color: #e6102d;
}
.LocationsPopup-filters {
  display: flex;
  position: absolute;
  height: 40px;
  bottom: 10px;
  left: 15px;
  right: 15px;
  z-index: 3;
}
.LocationsPopup--beer .LocationsPopup-filters--ciders {
  display: none;
}
.LocationsPopup--ciders .LocationsPopup-filters--beer {
  display: none;
}
.LocationsPopup-filter {
  position: relative;
  flex: 1 1;
  border-radius: 2px;
  background: #fff;
}
.LocationsPopup-filter + .LocationsPopup-filter {
  margin-left: 15px;
}
.LocationsPopup-filter-label {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  color: #7a7a7a;
  padding: 12px 35px 0 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.LocationsPopup-filter-label svg {
  position: absolute;
  right: 13px;
  top: 13px;
  height: 12px;
  width: 4px;
}
.LocationsPopup-filter select {
  font-size: 18px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
.LocationsPopup-filter select option {
  font-size: 18px;
}
.LocationsPopup-filter:after {
  content: " ";
  position: absolute;
  right: 28px;
  top: 5px;
  bottom: 5px;
  width: 1px;
  background: #edeef2;
}
.LocationsPopup-locations {
  height: 100%;
}
.LocationsPopup-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.LocationsPopup-loader:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: .5;
}
.LocationsPopup-loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  animation: spin .6s linear infinite reverse;
}
.LocationsPopup-loader .ball {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  animation: spin 1.2s infinite ease-in-out;
}
.LocationsPopup-loader .ball:after {
  position: absolute;
  content: '';
  background-color: #e6102d;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  top: 0px;
}
.LocationsPopup-loader .ball:nth-child(1) {
  animation-delay: 0s;
}
.LocationsPopup-loader .ball:nth-child(2) {
  animation-delay: -0.1s;
}
.LocationsPopup-loader .ball:nth-child(3) {
  animation-delay: -0.2s;
}
.LocationsPopup-loader .ball:nth-child(4) {
  animation-delay: -0.3s;
}
.LocationsPopup-loader .ball:nth-child(5) {
  animation-delay: -0.4s;
}
.LocationsPopup-loader .ball:nth-child(6) {
  animation-delay: -0.5s;
}
.LocationsPopup-loader .ball:nth-child(7) {
  animation-delay: -0.6s;
}
.LocationsPopup-loader .ball:nth-child(8) {
  animation-delay: -0.7s;
}
.LocationsPopup-loader .ball:nth-child(9) {
  animation-delay: -0.8s;
}
.LocationsPopup-loader .ball:nth-child(10) {
  animation-delay: -0.9s;
}
.LocationsPopup--loaded .LocationsPopup-loader {
  display: none;
}
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
