.Slider {
  position: relative;
  height: 100%;
}
.Slider-items {
  height: 100%;
}
.Slider .owl-carousel {
  touch-action: manipulation;
}
.Slider .owl-stage-outer,
.Slider .owl-stage,
.Slider .owl-item {
  height: 100%;
}
.Slider .owl-dots {
  display: none;
}
.Slider-item {
  height: 100%;
}
.Slider-item img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Slider-captionText {
  position: relative;
  flex: 1 1;
}
.Slider-caption {
  background: #fff;
  z-index: 9;
}
.Slider-captionValue.isExiting {
  position: absolute;
}
.Slider-captionValue.isEntering {
  display: none;
}
.Slider-counterCurrent {
  position: relative;
}
.Slider--index {
  height: auto;
}
.Slider--index .owl-stage-outer:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(0deg, transparent, rgba(28, 22, 48, 0.8));
  z-index: 6;
  pointer-events: none;
}
.Slider--index .Slider-items {
  height: 474px;
}
.Slider--index .Slider-caption {
  position: relative;
  height: 168px;
  margin-top: -100px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 16px;
  padding-left: 17px;
  padding-right: 17px;
}
.Slider--index .Slider-counter {
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  color: #bebdbd;
  margin-bottom: 7px;
}
.Slider--index .Slider-counterCurrent {
  color: #e6102d;
  display: inline-block;
  min-width: 10px;
  margin-right: -1px;
}
.Slider--index .Slider-counterCurrent[data-digits-count='2'] {
  min-width: 18px;
  margin-right: -2px;
}
.Slider--index .Slider-captionTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0;
  color: #1c1630;
  max-height: 75px;
  margin-left: -1px;
}
.Slider--index .Slider-captionLink {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0;
  color: #e6102d;
  display: inline-block;
  position: relative;
  margin-top: 10px;
}
.Slider--index .Slider-captionLink:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  opacity: .3;
  border-bottom: 3px solid #e6102d;
  transition: opacity 0.3s ease;
}
.Slider--index .Slider-captionLink:hover:after,
.Slider--index .Slider-captionLink:focus:after {
  opacity: 0;
}
.Slider--production,
.Slider--about,
.Slider--principles,
.Slider--career,
.Slider--post,
.Slider--excursion {
  padding-bottom: 0;
}
.Slider--production .owl-stage-outer:after,
.Slider--about .owl-stage-outer:after,
.Slider--principles .owl-stage-outer:after,
.Slider--career .owl-stage-outer:after,
.Slider--post .owl-stage-outer:after,
.Slider--excursion .owl-stage-outer:after {
  display: none;
}
.Slider--production .Slider-item,
.Slider--about .Slider-item,
.Slider--principles .Slider-item,
.Slider--career .Slider-item,
.Slider--post .Slider-item,
.Slider--excursion .Slider-item {
  position: relative;
}
.Slider--production .Slider-item:after,
.Slider--about .Slider-item:after,
.Slider--principles .Slider-item:after,
.Slider--career .Slider-item:after,
.Slider--post .Slider-item:after,
.Slider--excursion .Slider-item:after {
  content: '';
  display: block;
  padding-bottom: 70.4%;
}
.Slider--production .Slider-item img,
.Slider--about .Slider-item img,
.Slider--principles .Slider-item img,
.Slider--career .Slider-item img,
.Slider--post .Slider-item img,
.Slider--excursion .Slider-item img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Slider--production .Slider-caption,
.Slider--about .Slider-caption,
.Slider--principles .Slider-caption,
.Slider--career .Slider-caption,
.Slider--post .Slider-caption,
.Slider--excursion .Slider-caption {
  display: flex;
  height: 107px;
  padding-top: 26px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 15px;
}
.Slider--production .Slider-captionText,
.Slider--about .Slider-captionText,
.Slider--principles .Slider-captionText,
.Slider--career .Slider-captionText,
.Slider--post .Slider-captionText,
.Slider--excursion .Slider-captionText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: #2e2e33;
  margin-top: 1px;
  overflow: hidden;
}
.Slider--production .Slider-captionTitle,
.Slider--about .Slider-captionTitle,
.Slider--principles .Slider-captionTitle,
.Slider--career .Slider-captionTitle,
.Slider--post .Slider-captionTitle,
.Slider--excursion .Slider-captionTitle {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}
.Slider--production .Slider-counter,
.Slider--about .Slider-counter,
.Slider--principles .Slider-counter,
.Slider--career .Slider-counter,
.Slider--post .Slider-counter,
.Slider--excursion .Slider-counter {
  font-size: 14px;
  line-height: 20px;
}
.Slider--production .Slider-counterCurrent,
.Slider--about .Slider-counterCurrent,
.Slider--principles .Slider-counterCurrent,
.Slider--career .Slider-counterCurrent,
.Slider--post .Slider-counterCurrent,
.Slider--excursion .Slider-counterCurrent,
.Slider--production .Slider-counterTotal,
.Slider--about .Slider-counterTotal,
.Slider--principles .Slider-counterTotal,
.Slider--career .Slider-counterTotal,
.Slider--post .Slider-counterTotal,
.Slider--excursion .Slider-counterTotal {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.Slider--production .Slider-counter,
.Slider--about .Slider-counter,
.Slider--principles .Slider-counter,
.Slider--career .Slider-counter,
.Slider--post .Slider-counter,
.Slider--excursion .Slider-counter {
  text-align: center;
  color: #bebdbd;
  flex-shrink: 0;
  width: 40px;
  margin-right: 17px;
}
.Slider--production .Slider-counterCurrent,
.Slider--about .Slider-counterCurrent,
.Slider--principles .Slider-counterCurrent,
.Slider--career .Slider-counterCurrent,
.Slider--post .Slider-counterCurrent,
.Slider--excursion .Slider-counterCurrent {
  color: #e6102d;
}
.Slider--production .Slider-captionLink,
.Slider--about .Slider-captionLink,
.Slider--principles .Slider-captionLink,
.Slider--career .Slider-captionLink,
.Slider--post .Slider-captionLink,
.Slider--excursion .Slider-captionLink {
  display: none;
}
.Slider--production .Slider-inner,
.Slider--about .Slider-inner,
.Slider--principles .Slider-inner,
.Slider--career .Slider-inner,
.Slider--post .Slider-inner,
.Slider--excursion .Slider-inner {
  position: relative;
}
.Slider--production .Slider-inner:after,
.Slider--about .Slider-inner:after,
.Slider--principles .Slider-inner:after,
.Slider--career .Slider-inner:after,
.Slider--post .Slider-inner:after,
.Slider--excursion .Slider-inner:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-bottom: 1px solid #ebebf5;
}
.Slider--production {
  height: 264px;
}
.Slider--production .Slider-caption {
  left: 0;
}
.Slider--post {
  width: calc(100% + 35px);
  margin-left: -25px;
  background: #d8d8d8;
}
.Slider--post .Slider-captionText {
  padding-left: 0;
}
.Slider--excursion .Slider-caption {
  align-items: center;
  padding: 15px;
  height: auto;
}
.Slider--excursion .Slider-counter {
  width: auto;
}
.Slider--excursion .Slider-counterCurrent,
.Slider--excursion .Slider-counterTotal {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.Slider--excursion .Slider-captionText {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
}
