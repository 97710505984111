.TopNav {
  position: relative;
  z-index: 12;
}
.TopNav-inner {
  position: fixed;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: content-box;
  border-bottom: 1px solid #ebebf6;
  transition: transform 0.3s ease-in;
}
.TopNav-header {
  display: flex;
  position: relative;
  align-items: flex-start;
  height: 50px;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(235, 235, 245, 0);
  transition: background-color 0.3s ease, border-bottom-color 0.3s ease;
}
.TopNav-logo {
  position: relative;
  width: 50px;
  margin-top: 11px;
  padding-left: 15px;
  fill: #fff;
}
.TopNav-logo svg {
  display: block;
  height: 28px;
  width: 18px;
  top: 0;
  left: 0;
  pointer-events: none;
}
.TopNav-title {
  color: #1c1630;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  height: 100%;
  outline: none;
}
.TopNav-titleInner {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  position: relative;
}
.TopNav-titleInner svg {
  position: absolute;
  height: 5px;
  width: 8px;
  top: 0;
  bottom: 0;
  right: -13px;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0;
  stroke: #ceced9;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.TopNav-button {
  position: relative;
  height: 50px;
  width: 50px;
  margin-left: auto;
  background-color: #e6102d;
  transition: background-color 0.3s ease;
  z-index: 41;
}
.TopNav-button:before {
  content: '';
  position: absolute;
  height: 2px;
  width: 20px;
  top: 18px;
  left: 15px;
  background-color: #fff;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.TopNav-button:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 20px;
  top: 31px;
  left: 15px;
  background-color: #ffffff;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.TopNav-popup {
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: visibility 0.3s ease;
  z-index: 40;
}
.TopNav-overlay {
  visibility: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(15, 12, 26, 0.4);
  transition: all 0.3s ease;
}
.TopNav-body {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background-color: #e6102d;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}
.TopNav-links {
  padding-top: 20px;
}
.TopNav-link {
  font-size: 22px;
  font-weight: 500;
  line-height: 55px;
  color: #fff;
  display: block;
  height: 55px;
  padding-left: 25px;
  border-bottom: 1px solid #ff4c64;
}
.TopNav-link--ru {
  display: none;
}
body.lang--ru .TopNav-link--ru {
  display: block;
}
.TopNav-sections {
  visibility: hidden;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  padding-top: 1px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
  border-bottom: 1px solid #ebebf5;
  transition: transform 0.3s ease, visibility 0.3s ease;
  transform: translateY(calc(-100% - 50px));
}
.TopNav-sectionItem {
  font-size: 16px;
  font-weight: 500;
  line-height: 60px;
  color: #1c1630;
  display: block;
  position: relative;
  height: 60px;
  padding-left: 1px;
  border-bottom: 1px solid #ebebf5;
}
.TopNav-sectionItem:last-child {
  border-bottom: none;
}
.TopNav-sectionItem.isActive {
  color: #e6102d;
}
.TopNav-sectionItem.isActive:after {
  transition: transform 0.3s ease;
  transform: scaleX(1) scaleY(1);
}
.TopNav-sectionItem.isActive .TopNav-sectionItemTitle {
  transform: translateX(15px);
}
.TopNav-sectionItem:after {
  content: '';
  position: absolute;
  height: 4px;
  width: 4px;
  top: 0;
  bottom: 0;
  left: 2px;
  margin-top: auto;
  margin-bottom: auto;
  background: #e6102d;
  transition: transform 0.3s ease;
  transform: scaleX(0) scaleY(0);
  border-radius: 50%;
}
.TopNav-sectionItemTitle {
  transition: transform 0.3s ease, color 0.3s ease;
}
.TopNav.isActive .TopNav-header,
.TopNav.isPermanentActive .TopNav-header {
  background: #fff;
  border-bottom-color: #ebebf5;
}
.TopNav.isActive .TopNav-logo,
.TopNav.isPermanentActive .TopNav-logo {
  fill: #e6102d;
}
.TopNav.isActive .TopNav-title,
.TopNav.isPermanentActive .TopNav-title {
  color: #1c1630;
}
.TopNav.isActive .TopNav-titleInner svg,
.TopNav.isPermanentActive .TopNav-titleInner svg {
  opacity: 1;
}
.TopNav.isDropdownOpened .TopNav-header {
  background: #fff;
  border-bottom-color: #ebebf5;
}
.TopNav.isDropdownOpened .TopNav-logo {
  fill: #e6102d;
}
.TopNav.isDropdownOpened .TopNav-title {
  color: #1c1630;
}
.TopNav.isDropdownOpened .TopNav-sections {
  visibility: visible;
  transform: translateY(0);
}
.TopNav.isDropdownOpened .TopNav-titleInner svg {
  opacity: 1;
  transform: rotateZ(180deg);
}
.TopNav.isDropdownOpened .TopNav-overlay {
  visibility: visible;
  opacity: 1;
}
.TopNav.isOpened .TopNav-popup {
  visibility: inherit;
  transition: visibility 0s;
}
.TopNav.isOpened .TopNav-overlay {
  visibility: visible;
  opacity: 1;
}
.TopNav.isOpened .TopNav-body {
  transform: translate(0, 0);
}
.TopNav.isOpened .TopNav-button {
  background-color: #fff;
}
.TopNav.isOpened .TopNav-button:before {
  background: #e6102d;
  transform: translateY(6px);
}
.TopNav.isOpened .TopNav-button:after {
  background: rgba(255, 255, 255, 0);
  transform: translateY(-6px);
}
.TopNav--index .TopNav-inner,
.TopNav--brands .TopNav-inner,
.TopNav--media .TopNav-inner {
  border-bottom-color: transparent;
}
.TopNav.TopNav--people .TopNav-inner,
.TopNav.TopNav--career .TopNav-inner,
.TopNav.TopNav--about .TopNav-inner {
  border-bottom: 1px solid #ebebf6;
}
.TopNav.TopNav--career .TopNav-inner,
.TopNav.TopNav--people .TopNav-inner,
.TopNav.TopNav--about .TopNav-inner,
.TopNav.TopNav--history .TopNav-inner,
.TopNav.TopNav--production .TopNav-inner,
.TopNav.TopNav--principles .TopNav-inner,
.TopNav.TopNav--contact .TopNav-inner,
.TopNav.TopNav--brand .TopNav-inner {
  width: 100%;
  background-color: #fff;
}
.TopNav.TopNav--career .TopNav-logo,
.TopNav.TopNav--people .TopNav-logo,
.TopNav.TopNav--about .TopNav-logo,
.TopNav.TopNav--history .TopNav-logo,
.TopNav.TopNav--production .TopNav-logo,
.TopNav.TopNav--principles .TopNav-logo,
.TopNav.TopNav--contact .TopNav-logo,
.TopNav.TopNav--brand .TopNav-logo {
  fill: #e6102d;
}
.TopNav.TopNav--career .TopNav-title,
.TopNav.TopNav--people .TopNav-title,
.TopNav.TopNav--about .TopNav-title,
.TopNav.TopNav--history .TopNav-title,
.TopNav.TopNav--production .TopNav-title,
.TopNav.TopNav--principles .TopNav-title,
.TopNav.TopNav--contact .TopNav-title,
.TopNav.TopNav--brand .TopNav-title {
  margin-top: 2px;
}
.TopNav.TopNav--career .TopNav-link:not(:hover),
.TopNav.TopNav--people .TopNav-link:not(:hover),
.TopNav.TopNav--about .TopNav-link:not(:hover),
.TopNav.TopNav--history .TopNav-link:not(:hover),
.TopNav.TopNav--production .TopNav-link:not(:hover),
.TopNav.TopNav--principles .TopNav-link:not(:hover),
.TopNav.TopNav--contact .TopNav-link:not(:hover),
.TopNav.TopNav--brand .TopNav-link:not(:hover) {
  transition: color 0.15s ease;
}
.TopNav--about .TopNav-titleInner svg,
.TopNav--history .TopNav-titleInner svg,
.TopNav--principles .TopNav-titleInner svg,
.TopNav--people .TopNav-titleInner svg,
.TopNav--production .TopNav-titleInner svg,
.TopNav--brands .TopNav-titleInner svg,
.TopNav--brand .TopNav-titleInner svg {
  display: none;
}
.TopNav--brands .TopNav-titleInner {
  color: #fff;
  transition: color 0.3s ease;
}
.TopNav--brands.isActive .TopNav-titleInner {
  color: inherit;
}
