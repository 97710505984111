.Responsible-content {
  position: relative;
  padding-top: 98px;
  padding-bottom: 141px;
  padding-left: 15px;
  padding-right: 15px;
}
.Responsible-header {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  color: #1c1630;
}
.Responsible-text {
  font-size: 16px;
  line-height: 25px;
  color: #1c1630;
  margin-top: 37px;
}
.Responsible-text p + p {
  margin-top: 25px;
}
.Responsible-link {
  margin-top: 92px;
  padding-left: 25px;
}
.ResponsiblePage .Content {
  background: #fafafc;
}
.ResponsiblePage .Content-footer {
  display: none;
}
