.LegalDocuments {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 51px;
  background: #fafafc;
}
.LegalDocuments-backgroundImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-mask-image: linear-gradient(black, transparent);
          mask-image: linear-gradient(black, transparent);
}
.LegalDocuments-menuTop {
  width: 100%;
  z-index: 1;
}
.LegalDocuments-contentWrapper {
  padding: 15px;
}
.LegalDocuments-header {
  color: #1c1630;
  width: 100%;
  margin-bottom: 50px;
  padding-top: 55px;
  z-index: 1;
}
.LegalDocuments-subtitle {
  display: flex;
  margin-bottom: 4px;
}
.LegalDocuments-subtitleStroke {
  height: 19px;
  width: 109px;
  margin-right: 11px;
  border-bottom: 1px solid #e6102d;
}
.LegalDocuments-subtitleText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2.75px;
  color: #e6102d;
  text-transform: uppercase;
}
.LegalDocuments-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 40px;
}
.LegalDocuments-text {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
  margin-bottom: 80px;
}
.LegalDocuments-slider {
  margin-bottom: 2px;
}
.LegalDocuments-production {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 27px;
  padding-top: 17px;
}
.LegalDocuments-logos {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  border-top: 1px solid #edeef2;
  border-left: 1px solid #edeef2;
}
.LegalDocuments-logosItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-bottom: 1px solid #edeef2;
  border-right: 1px solid #edeef2;
}
.LegalDocuments-logosItem:before {
  content: '';
  float: left;
  padding-top: 80%;
}
.LegalDocuments-logo {
  width: 47%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.LegalDocuments-brands:last-of-type {
  margin-bottom: 0;
}
.LegalDocuments-brandsSection {
  padding-bottom: 40px;
}
.LegalDocuments-brandsTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  position: relative;
  margin-bottom: 47px;
}
.LegalDocuments-brandsTitle:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 30px;
  top: 0;
  left: 0;
  margin-top: 21px;
  border-bottom: 1px solid red;
}
.LegalDocuments-brandsItem {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 21px 0 19px 0;
  border-bottom: 1px solid #edeef2;
  transition: color 0.3s ease;
}
.LegalDocuments-brandsHeader {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1c1630;
}
.LegalDocuments-brandsDescription {
  font-family: 'Graphik LCG', Arial, sans-serif;
  position: relative;
  top: 4px;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: right;
  color: #ccd6dd;
  margin-right: 2px;
}
.LegalDocuments-links {
  margin-top: -30px;
}
.LegalDocuments-archiveLink {
  color: #e60f2d;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  text-decoration: none;
}
.LegalDocuments-archiveLink:hover {
  color: #cc001b;
}
.LegalDocuments-archiveLink svg {
  fill: currentColor;
  flex-shrink: 0;
  height: 16px;
  width: 29px;
}
