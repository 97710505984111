.Notify {
  display: flex;
  position: fixed;
  min-height: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 25;
}
.Notify-img {
  align-self: flex-end;
  height: 44px;
  width: 84px;
}
.Notify-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Notify-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #1c1630;
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 10px;
}
.Notify-text a {
  color: #e6102d;
  display: inline-block;
  position: relative;
  margin-left: 2px;
}
.Notify-text a:hover:after,
.Notify-text a:focus:after {
  border-color: rgba(230, 16, 45, 0);
}
.Notify-text a:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 1px;
  right: 1px;
  border-bottom: 2px solid rgba(230, 16, 45, 0.3);
  transition: border-color 0.3s ease;
}
.Notify-close {
  display: flex;
  align-items: center;
  align-self: stretch;
}
