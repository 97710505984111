.Brands {
  background: red;
}
.Brands-head {
  position: relative;
  height: 460px;
}
.Brands-head .Video-blackout {
  background: rgba(28, 22, 48, 0.2);
}
.Brands-head:before {
  content: '';
  position: absolute;
  height: 141px;
  top: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.58819) 9.99%, hsla(0, 0%, 0%, 0.55556) 19.07%, hsla(0, 0%, 0%, 0.50625) 27.44%, hsla(0, 0%, 0%, 0.44444) 35.26%, hsla(0, 0%, 0%, 0.37431) 42.72%, hsla(0, 0%, 0%, 0.3) 50%, hsla(0, 0%, 0%, 0.22569) 57.28%, hsla(0, 0%, 0%, 0.15556) 64.74%, hsla(0, 0%, 0%, 0.09375) 72.56%, hsla(0, 0%, 0%, 0.04444) 80.93%, hsla(0, 0%, 0%, 0.01181) 90.01%, hsla(0, 0%, 0%, 0));
  z-index: 1;
}
.Brands-headNav {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  right: 0;
}
.Brands-headContent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.Brands-headMedia {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.Brands-img {
  height: 100%;
  width: 100%;
}
.Brands-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Brands-img:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(28, 22, 48, 0.2);
}
.Brands-header {
  padding-right: 15px;
  position: absolute;
  width: 100%;
  left: 15px;
  margin-top: 139px;
}
.Brands-title {
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 3px;
  letter-spacing: 2.75px;
  text-transform: uppercase;
  color: #e6102d;
}
.Brands-title:before {
  content: '';
  display: inline-block;
  width: 109px;
  margin-right: 11px;
  border-bottom: 1px solid #e6102d;
}
.Brands-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  color: #fff;
  margin-top: 6px;
  margin-top: 46px;
}
