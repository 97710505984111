.BrandsList {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}
.BrandsList-grid {
  padding-top: 1px;
  border-left: 1px solid #edeef2;
}
.BrandsList-categories {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin-top: -100px;
  background: transparent;
}
.BrandsList-category {
  text-align: center;
  color: #0f0c1a;
  display: block;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 54px;
  transition: color 0.3s ease;
  cursor: pointer;
}
.BrandsList-category div {
  width: 100%;
}
.BrandsList-category.isActive {
  color: #e6102d;
}
.BrandsList-categorySvg {
  height: 11px;
  width: 100%;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.15s ease;
}
.isActive .BrandsList-categorySvg {
  opacity: 1;
}
.BrandsList-categorySvg svg {
  height: 18px;
  width: 23px;
  fill: white;
}
.BrandsList-categoryIcon {
  display: block;
  position: relative;
  margin: 0 auto;
  fill: currentColor;
  stroke: none;
  transition: stroke 0.3s ease, fill 0.3s ease;
}
.BrandsList-categoryIcon svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.BrandsList-category--beer .BrandsList-categoryIcon {
  height: 30px;
  width: 15px;
}
.BrandsList-category--ciders .BrandsList-categoryIcon {
  height: 42px;
  width: 18px;
}
.BrandsList-category--beverages .BrandsList-categoryIcon {
  height: 37px;
  width: 16px;
}
.BrandsList-categoryTitle {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  margin-top: 8px;
}
.BrandsList-content {
  position: relative;
  width: 100%;
  background: #fff;
}
.BrandsList-subCategories {
  display: flex;
  width: 100%;
  background: #fff;
}
.BrandsList-subCategoriesTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  color: #1c1630;
  flex: 1 1;
}
.BrandsList-subCategoriesList {
  flex: 1 1;
  display: flex;
  align-items: center;
  height: 45px;
  margin-right: 20px;
  padding: 0 20px;
  overflow-x: scroll;
}
.BrandsList-subCategoriesLabel {
  position: relative;
  height: 45px;
  width: 45px;
  border-left: 1px solid #edeef2;
}
.BrandsList-subCategoriesLabel--active:after {
  content: ' ';
  position: absolute;
  top: 12px;
  left: 17px;
  width: 8px;
  height: 8px;
  background: #e6102d;
  border: 1px solid #fff;
  border-radius: 8px;
}
.BrandsList-subCategoriesLabel:before {
  content: '';
  position: absolute;
  height: 45px;
  width: 50px;
  top: 0;
  left: -58px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  pointer-events: none;
}
.BrandsList-subCategoriesLabel svg {
  position: absolute;
  height: 16px;
  width: 16px;
  top: 15px;
  left: 13px;
}
.BrandsList-subCategory {
  font-size: 15px;
  font-weight: 500;
  color: #1c1630;
  background: none;
  border: none;
  transition: color 0.3s ease;
  cursor: pointer;
}
.BrandsList-subCategory:not(:last-child) {
  margin-right: 32px;
}
.BrandsList-subCategory.isActive {
  color: #e6102d;
}
.BrandsList-map {
  position: absolute;
  width: 168px;
  bottom: calc(100% + 26px);
  margin-left: 24px;
  left: calc(((100% - (11 - 1) * 48px - 1 * 48px - 0.5 * 48px) / 11 * 9 + 8 * 48px + 2 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .BrandsList-map {
    left: calc(((100% - (11 - 1) * 32px - 1 * 32px - 0.5 * 2 * 48px) / 11 * 9 + 8 * 32px + 2 * 32px + 0 * 48px) + 0px);
  }
}
