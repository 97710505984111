*,
*:after,
*:before {
  box-sizing: border-box;
}
body {
  font-family: 'Graphik LCG', Arial, sans-serif;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: 'kern';
  -ms-font-feature-settings: 'kern' 1;
  font-feature-settings: 'kern';
  background: #fafafc;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  font-family: inherit;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}
