.Content {
  position: relative;
  background: #fafafc;
}
.Content--scalingOut .Content-main {
  transform: scale3d(0.8, 0.8, 1);
}
.Content--scalingOut .Content-overlay {
  opacity: 1;
}
.Content-main {
  transition: transform 0.7s ease;
}
.Content-main .PostPage,
.Content-main .LocationsPage,
.ExcursionPage .Content-main {
  display: none;
}
.Content-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.8);
  transition: opacity 0.7s ease;
  z-index: 21;
  pointer-events: none;
}
