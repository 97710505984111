.BuildingCross {
  display: flex;
  position: absolute;
  justify-content: center;
  align-content: center;
  height: 25px;
  width: 25px;
  z-index: 1;
  border-radius: 50%;
}
.BuildingCross[state='foreground'] .BuildingCross-cross {
  background: #e6102d;
  transition: background 0.7s ease, transform 0.3s ease;
  transform: rotate(180deg);
}
.BuildingCross[state='foreground'] .BuildingCross-cross svg {
  fill: white;
  transition: fill 0.3s ease;
}
.BuildingCross[state='foreground'] .BuildingCross-label {
  opacity: 1;
  transition: opacity 0.15s ease, transform 0.3s ease;
  transform: translate(-107px, -81px);
}
.BuildingCross-cross {
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
  align-self: center;
  height: 25px;
  width: 25px;
  background: white;
  transition: background 0.7s ease 0.3s, transform 0.3s ease 0.15s;
  transform: rotate(0);
  border-radius: 50%;
  box-shadow: 0 0 0 3pt rgba(0, 0, 0, 0.15);
}
.BuildingCross-cross svg {
  height: 100%;
  width: 100%;
  padding: 7px;
  fill: #0f0c1a;
  transition: fill 0.3s ease 0.3s;
  border-radius: 50%;
}
.BuildingCross-label {
  position: absolute;
  width: 240px;
  bottom: -40px;
  left: 0;
  padding: 9px 19px 12px 19px;
  opacity: 0;
  background: white;
  transition: opacity 0.3s ease 0.15s, transform 0.3s ease 0.15s;
  transform: translate(-107px, -71px);
  z-index: 1;
  pointer-events: none;
  border-radius: 2px;
  -webkit-filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.4));
}
.BuildingCross-label:before {
  content: '';
  position: absolute;
  height: 12px;
  width: 12px;
  top: 100%;
  left: 50%;
  background: white;
  transform: translate(-50%, -50%) rotate(45deg);
}
.BuildingCross-label svg {
  height: 100%;
  width: 100%;
  fill: white;
  -webkit-filter: url(#shadowFilter);
          filter: url(#shadowFilter);
}
.BuildingCross-header {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 20px;
  line-height: 29px;
}
.BuildingCross-description {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 12px;
}
