.PostList {
  background: #fafafc;
}
.PostList-sectionHeader {
  display: flex;
  height: 72px;
  border-bottom: 1px solid #ebebf6;
  padding-left: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
  padding-right: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 0 + 0 * 48px + 0 * 48px + 1 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-sectionHeader {
    padding-left: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
@media (max-width: 1199px) {
  .PostList-sectionHeader {
    padding-right: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 0 + 0 * 32px + 0 * 32px + 1 * 48px) + 0px);
  }
}
.PostList-header {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  color: #1c1630;
  align-self: center;
  width: calc(((100% - (12 - 1) * 48px - 0 * 48px - 0 * 48px) / 12 * 1 + 0 * 48px + 1 * 48px + 0 * 48px / 2) + 0px);
}
@media (max-width: 1199px) {
  .PostList-header {
    width: calc(((100% - (12 - 1) * 32px - 0 * 32px - 0 * 2 * 48px) / 12 * 1 + 0 * 32px + 1 * 32px + 0 * 48px) + 0px);
  }
}
.PostList-filters {
  display: flex;
  position: relative;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebf5;
  overflow: auto;
}
.PostList-description {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: none;
}
.PostList-description.isActive {
  display: block;
}
.PostList-sectionList {
  display: flex;
  align-items: center;
  flex: 1 1;
  height: 100%;
  padding-left: 15px;
  overflow-x: auto;
}
.PostList-sectionItem {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;
  color: #1c1630;
  display: inline-block;
  margin-top: 2px;
  transition: color 0.3s ease;
}
.PostList-sectionItem:not(:last-child) {
  margin-right: 23px;
}
.PostList-sectionItem:last-child {
  padding-right: 45px;
}
.PostList-sectionItem:hover,
.PostList-sectionItem:focus {
  color: #e6102d;
}
.PostList-sectionItem.isActive {
  color: #e6102d;
  pointer-events: none;
}
.PostList-tagsList {
  width: 100%;
}
.PostList-tagsButton {
  position: relative;
  height: 100%;
  width: 47px;
  border-left: 1px solid #ebebf5;
  fill: #919199;
  z-index: 2;
}
.PostList-tagsButton svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 16px;
  width: 16px;
}
.PostList-tagsButton:active {
  opacity: .6;
}
.PostList-tagsButton.hasTags:after {
  content: '';
  position: absolute;
  height: 9px;
  width: 9px;
  top: 11px;
  left: 18px;
  background: #e6102d;
  border: 1px solid #fff;
  border-radius: 50%;
}
.PostList-tags {
  position: relative;
}
.PostList-main {
  position: relative;
}
.PostList-favorite {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  height: 460px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.PostList-favoriteMeta {
  display: flex;
  position: relative;
  align-items: flex-end;
  margin-bottom: 4px;
  z-index: 3;
}
.PostList-favoriteBadge {
  margin-right: 9px;
}
.PostList-favoriteDate {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: .85px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 5px;
  margin-right: 14px;
}
.PostList-favoriteExternalLink {
  font-size: 11px;
  line-height: 11px;
  letter-spacing: .85px;
  text-transform: uppercase;
  color: #e6102d;
  position: relative;
  margin-bottom: 5px;
}
.PostList-favoriteExternalLink:after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  border-bottom: 4px solid rgba(230, 16, 45, 0.3);
  transition: opacity 0.3s ease;
}
.PostList-favoriteExternalLink:hover:after,
.PostList-favoriteExternalLink:focus:after {
  opacity: 0;
}
.PostList-favoriteContent {
  display: block;
}
.PostList-favoriteContent:hover .PostList-favoriteImg:before,
.PostList-favoriteContent:focus .PostList-favoriteImg:before {
  opacity: 1;
}
.PostList-favoriteImg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(28, 22, 48, 0.73);
}
.PostList-favoriteImg img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.PostList-favoriteImg:after {
  content: '';
  position: absolute;
  height: 403px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 27%, #231e29 74%);
  z-index: 1;
}
.PostList-favoriteImg:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.2);
  transition: opacity 0.3s ease;
  z-index: 2;
}
.PostList-favoriteTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: #fff;
  display: inline;
  position: relative;
  z-index: 3;
}
.PostList-favoriteSubtitle {
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  position: relative;
  margin-top: 3px;
  opacity: .8;
  z-index: 3;
}
.PostList-news {
  position: relative;
  width: 100%;
  padding-top: 22px;
  overflow-x: auto;
  padding-left: 15px;
  border-bottom: 1px solid #ebebf6;
  -webkit-overflow-scrolling: touch;
}
.PostList-newsHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: #1c1630;
  position: absolute;
  top: 22px;
  left: 15px;
}
.PostList-newsList {
  display: flex;
}
.PostList-newsItem {
  position: relative;
  flex-shrink: 0;
  width: calc(100vw - 40px);
  overflow: hidden;
}
.PostList-newsLink {
  display: flex;
  height: 137px;
  padding-top: 47px;
  padding-bottom: 13px;
}
.PostList-newsLink:hover .PostList-newsTitle,
.PostList-newsLink:focus .PostList-newsTitle {
  color: #e6102d;
}
.PostList-newsNumber {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 188px;
  font-weight: 500;
  line-height: 188px;
  text-align: center;
  color: #edeef2;
  position: absolute;
  bottom: -39px;
  right: 44px;
}
.PostList-newsContent {
  display: flex;
}
.PostList-newsText {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  padding-left: 15px;
  padding-right: 21px;
  z-index: 2;
}
.PostList-newsTitle {
  font-size: 16px;
  line-height: 20px;
  color: #1c1630;
  max-height: 60px;
  overflow: hidden;
  transition: color 0.3s ease;
}
.PostList-newsDate {
  font-size: 11px;
  line-height: 11px;
  color: #919199;
}
.PostList-newsImage {
  flex-shrink: 0;
  height: 65px;
  width: 95px;
  margin-top: 5px;
}
.PostList-newsImage img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  border-radius: 2px;
}
.PostList-newsButton {
  margin-top: auto;
}
.PostListPage .Content {
  background: #fff;
}
.PostListPage .TopNav-title {
  color: #fff;
}
.PostListPage .TopNav-title svg {
  opacity: 1;
  stroke: #fff;
}
.PostListPage .TopNav.isActive .TopNav-title svg,
.PostListPage .TopNav.isPermanentActive .TopNav-title svg {
  stroke: #ceced9;
}
