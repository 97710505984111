.People {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 50px;
}
.People-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #0f0c1a;
}
.People-backgroundImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-mask-image: linear-gradient(black, transparent);
          mask-image: linear-gradient(black, transparent);
}
.People-menuTop {
  width: 100%;
  z-index: 1;
}
.People-contentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
}
.People-header {
  width: 100%;
  margin-bottom: 50px;
  padding-top: 55px;
  z-index: 1;
}
.People-subtitle {
  display: flex;
  margin-bottom: 4px;
}
.People-subtitleStroke {
  height: 19px;
  width: 109px;
  margin-right: 11px;
  border-bottom: 1px solid #e6102d;
}
.People-subtitleText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2.75px;
  color: #e6102d;
}
.People-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  line-height: 34px;
  color: #f7f8fc;
}
.People-item {
  display: block;
  position: relative;
  flex: 0 1 100%;
  height: 255px;
  margin-bottom: 20px;
  z-index: 1;
}
.People-image {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.People-text {
  line-height: 18px;
  position: absolute;
  width: calc(100% / 345 * 180);
  bottom: 0;
  left: 0;
  padding: 9px 10px 8px 9px;
  background: #f7f8fc;
}
.People-name {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #1c1630;
  margin-bottom: 1px;
}
.People-position {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 11px;
  color: #1c1630;
  padding-left: 1px;
  opacity: .7;
}
