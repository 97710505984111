.PostGrid {
  padding-top: 25px;
  padding-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
}
.PostGrid.isReady {
  opacity: 1;
}
.PostGrid-sizer {
  width: 100%;
}
.PostGrid-sizer:after {
  content: '';
  display: block;
  padding-bottom: 108.32%;
}
.PostGrid-item {
  position: relative;
  height: 342px;
  width: 20%;
  background: #fff;
  transition: z-index 0.3s ease;
  z-index: 1;
}
.PostGrid-item--isStamp {
  position: absolute;
  top: 0;
  right: 0;
}
.PostGrid-item:after {
  content: '';
  position: absolute;
  top: -1px;
  bottom: 0;
  left: -1px;
  right: 0;
  border: 1px solid #edeef2;
  transition: border-color 0.3s ease;
}
.PostGrid-item[data-last-column='true'].PostGrid-item:after {
  right: 0;
}
.PostGrid-item:hover,
.PostGrid-item:focus {
  z-index: 2;
}
.PostGrid-item:hover:after,
.PostGrid-item:focus:after {
  border-color: #e6102d;
}
.PostGrid-loader {
  visibility: hidden;
  position: absolute;
  height: 10px;
  width: 50px;
  bottom: 94px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}
.PostGrid-loader.isActive {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s linear 0s;
}
.PostGrid-centerLoader {
  visibility: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  z-index: 11;
}
.PostGrid-centerLoader.isActive {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s linear 0s;
}
.PostGrid-centerLoaderInner {
  position: absolute;
  height: 10px;
  width: 50px;
  top: calc(50vh - 101px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.PostGrid-loaderDot {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 0;
  background: #e6102d;
  border-radius: 50%;
}
.PostGrid-loaderDot:nth-child(1) {
  left: 0;
  animation: PostGrid-dotAnimation 0.75s linear 0s infinite alternate;
}
.PostGrid-loaderDot:nth-child(2) {
  left: 20px;
  animation: PostGrid-dotAnimation 0.75s linear 0.25s infinite alternate;
}
.PostGrid-loaderDot:nth-child(3) {
  left: 40px;
  animation: PostGrid-dotAnimation 0.75s linear 0.5s infinite alternate;
}
@keyframes PostGrid-dotAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: .33;
  }
}
