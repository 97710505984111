.Legal {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 51px;
  background: #fafafc;
}
.Legal-backgroundImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .3;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-mask-image: linear-gradient(black, transparent);
          mask-image: linear-gradient(black, transparent);
}
.Legal-menuTop {
  width: 100%;
  z-index: 1;
}
.Legal-contentWrapper {
  padding: 15px;
  margin-top: 51px;
}
.Legal-header {
  color: #1c1630;
  width: 100%;
  margin-bottom: 24px;
  z-index: 1;
}
.Legal-subtitle {
  display: flex;
  margin-bottom: 4px;
}
.Legal-subtitleStroke {
  height: 19px;
  width: 109px;
  margin-right: 11px;
  border-bottom: 1px solid #e6102d;
}
.Legal-subtitleText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2.75px;
  color: #e6102d;
  text-transform: uppercase;
}
.Legal-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 24px;
}
.Legal-content {
  margin-bottom: 30px;
}
.Legal-text {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
}
.Legal-text p {
  margin-bottom: 22px;
}
.Legal-text a {
  color: #e6102d;
}
