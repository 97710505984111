.Footer {
  position: relative;
  padding-bottom: 30px;
  background-color: #0f0c1a;
  z-index: 11;
}
.Footer a {
  text-decoration: none;
}
.Footer-background {
  position: absolute;
  height: 0;
  width: 100%;
  bottom: 0;
  padding-bottom: 153.86%;
}
.Footer-background svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}
.Footer-logo {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-left: 15px;
  padding-top: 30px;
  z-index: 1;
}
.Footer-logo svg {
  height: 29px;
  width: 140px;
}
.Footer-logoLink {
  position: relative;
  transition: opacity 0.3s ease;
}
.Footer-logoLink:hover,
.Footer-logoLink:focus {
  opacity: .7;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.Footer-row {
  position: relative;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 0;
  z-index: 1;
}
.Footer-row--first {
  margin-top: 43px;
}
.Footer-row--second {
  margin-top: 40px;
}
@media (width: 375px) {
  .Footer-row {
    width: 374px;
  }
}
.Footer-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  color: #fff;
  margin-bottom: 12px;
}
.Footer-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #78728c;
  display: block;
  transition: color 0.7s ease;
}
.Footer-link--confidential {
  margin-bottom: 24px;
  line-height: 20px;
}
.Footer-link--ru {
  display: none;
}
body.lang--ru .Footer-link--ru {
  display: block;
}
body.lang--ru .Footer-link--ru.Footer-brands {
  display: inline-block;
}
.Footer-link--not-ru {
  display: block;
}
body.lang--ru .Footer-link--not-ru {
  display: none;
}
body.lang--ru .Footer-link--not-ru.Footer-brands {
  display: none;
}
.Footer-link:hover,
.Footer-link:focus {
  color: #ebebf6;
  transition: color 0.3s ease;
}
.Footer-company {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 8px;
  z-index: 1;
}
.Footer-brandy {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 8px;
  z-index: 1;
}
.Footer-brands.Footer-link--ru {
  @me vertical-align: top;
  padding: 0 8px;
  width: 50%;
  z-index: 1;
}
.Footer-social {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 4px;
  padding-right: 15px;
  z-index: 1;
}
.Footer-socialLink {
  position: relative;
  height: 40px;
  width: 40px;
  transition: opacity 0.3s ease;
  z-index: 1;
}
@media (max-width: 374px) {
  .Footer-socialLink {
    margin-right: 0 !important;
    width: 30px;
    height: 30px;
  }
}
.Footer-socialLink:hover,
.Footer-socialLink:focus {
  opacity: .7;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.Footer-socialLink svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  fill: #e4012b;
  height: 20px;
  width: 20px;
}
.Footer-socialLink:not(:last-child) {
  margin-right: 5px;
}
.Footer-socialLink--twitter {
  margin-right: 5px;
}
.Footer-socialLink--twitter svg {
  height: 17px;
  width: 20px;
}
.Footer-socialLink--telegram {
  margin-right: 5px;
}
.Footer-socialLink--telegram svg {
  height: 18px;
  width: 20px;
}
.Footer-socialLink--facebook {
  margin-right: 6px;
}
.Footer-socialLink--facebook svg {
  height: 20px;
  width: 11px;
}
.Footer-socialLink--instagram svg {
  height: 20px;
  width: 20px;
}
.Footer-socialLink--vk svg {
  height: 20px;
  width: 20px;
}
.Footer-languagesCol {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 2px 8px 0;
  z-index: 1;
}
.Footer-languages {
  margin-bottom: 12px;
  min-height: 26px;
}
.Footer-languageLink {
  display: inline-block;
  height: 22px;
  width: 22px;
  margin-right: 13px;
  overflow: hidden;
  transition: opacity 0.3s ease;
  z-index: 1;
  border-radius: 50%;
  background: red;
  position: relative;
}
.Footer-languageLink:not(.isActive) {
  opacity: .3;
  transition: opacity 0.3s ease;
}
.Footer-languageLink:last-of-type {
  margin-right: 0;
}
.Footer-languageLink:hover,
.Footer-languageLink:focus {
  opacity: .7;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.Footer-languageLink svg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.Footer-plusEighteen {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}
.Footer-plusEighteen svg {
  height: 24px;
  width: 24px;
}
.Footer-subscribe {
  text-align: left;
  position: relative;
  padding-top: 10px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  background: #1B1825;
  z-index: 1;
}
.Footer-subscribe .Subscribe {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.Footer-subscribe .Subscribe-title {
  font-size: 16px;
  line-height: 25px;
  color: #f7f8fc;
  margin-bottom: 12px;
}
.Footer-subscribe .Subscribe-title:hover,
.Footer-subscribe .Subscribe-title:focus {
  cursor: pointer;
}
.Footer-subscribe .Subscribe-email {
  font-size: 14px;
  line-height: 16px;
  flex: 1 1;
  height: 36px;
  padding: 10px 20px 10px;
  background: #2B2738;
}
.Footer-subscribe .Subscribe-email::-webkit-input-placeholder {
  color: #78728c;
}
.Footer-subscribe .Subscribe-email::-ms-input-placeholder {
  color: #78728c;
}
.Footer-subscribe .Subscribe-email::placeholder {
  color: #78728c;
}
.Footer-subscribe .Subscribe-email:focus {
  background: #fff;
}
.Footer-subscribe .Subscribe-submit {
  position: relative;
  flex-shrink: 1;
  height: 36px;
  width: 72px;
}
.Footer-subscribe .Subscribe-submit svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  height: 10px;
  width: 15px;
}
.Footer-copy {
  position: absolute;
  right: 15px;
  width: calc(50% - 8px - 15px);
  bottom: -4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #78728c;
  z-index: 1;
}
body.lang--ru .Footer-copy {
  bottom: 5px;
}
