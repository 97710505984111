.Story[data-id='2024-1'] {
  background: #fff;
  color: #1c1630;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.Story[data-id='2024-1'] .Story-bg {
  background: #558f49;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translate(130%, 0);
  z-index: 1;
  width: 100%;
  will-change: transform;
}
.Story[data-id='2024-1'] .Story-content {
  margin: 0 auto;
  position: relative;
  padding: 50px 0;
  text-align: center;
  z-index: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-1'] .Story-content {
    padding: 30px 0 50px;
  }
}
.Story[data-id='2024-1'] .Story-text {
  font-size: 35px;
  line-height: 42px;
  position: relative;
  height: 84px;
}
.Story[data-id='2024-1'] .Story-text b {
  font-weight: 500;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-1'] .Story-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    height: 24px;
  }
  .Story[data-id='2024-1'] .Story-text br {
    display: none;
  }
}
.Story[data-id='2024-1'] .Story-textContent {
  color: #1c1630;
  position: absolute;
  inset: 0;
}
.Story[data-id='2024-1'] .Story-textContent--duplicate {
  color: #fff;
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
  will-change: clip-path;
}
.Story[data-id='2024-1'] .Story-imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px 0;
  margin: 40px auto 0;
  position: relative;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-1'] .Story-imgWrapper {
    flex-wrap: wrap;
  }
}
.Story[data-id='2024-1'] .Story-img0,
.Story[data-id='2024-1'] .Story-img1,
.Story[data-id='2024-1'] .Story-img2,
.Story[data-id='2024-1'] .Story-img3 {
  opacity: 1;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  pointer-events: none;
  margin: 0 -30px;
  will-change: opacity, transform;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-1'] .Story-img0,
  .Story[data-id='2024-1'] .Story-img1,
  .Story[data-id='2024-1'] .Story-img2,
  .Story[data-id='2024-1'] .Story-img3 {
    flex-wrap: wrap;
    width: 230px;
  }
}
@media (max-width: 413px) {
  .Story[data-id='2024-1'] .Story-img0,
  .Story[data-id='2024-1'] .Story-img1,
  .Story[data-id='2024-1'] .Story-img2,
  .Story[data-id='2024-1'] .Story-img3 {
    width: 210px;
  }
}
@media (max-width: 374px) {
  .Story[data-id='2024-1'] .Story-img0,
  .Story[data-id='2024-1'] .Story-img1,
  .Story[data-id='2024-1'] .Story-img2,
  .Story[data-id='2024-1'] .Story-img3 {
    width: 180px;
  }
}
.Story[data-id='2024-2'] {
  background: #fde7e7;
  color: #1c1630;
  position: relative;
  overflow: hidden;
  margin-top: 0 !important;
  height: auto;
  width: 100%;
}
.Story[data-id='2024-2'] .Story-inner {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}
.Story[data-id='2024-2'] .Story-content {
  padding: 30px;
  margin: 0 0 auto;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-2'] .Story-content {
    padding: 15px 15px 0;
  }
}
.Story[data-id='2024-2'] .Story-text {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-2'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2024-2'] .Story-text b {
  font-weight: 500;
}
.Story[data-id='2024-2'] .Story-imgWrapper {
  margin: 40px auto 0;
  position: relative;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-2'] .Story-imgWrapper {
    margin: 0 auto -15px;
    width: 140px;
  }
}
.Story[data-id='2024-2'] .Story-img {
  display: block;
  opacity: 1;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
  max-width: 100%;
  pointer-events: none;
  will-change: opacity, transform;
}
.Story[data-id='2024-3'] {
  background: #db3636;
  color: #fff;
  margin-top: 0 !important;
  height: 172px;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.Story[data-id='2024-3'] .Story-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.Story[data-id='2024-3'] .Story-content {
  margin: auto 0 0;
  padding: 0 30px 30px;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-3'] .Story-content {
    padding: 0 15px 15px;
  }
}
.Story[data-id='2024-3'] .Story-text {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  max-width: 140px;
  position: relative;
  z-index: 2;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-3'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2024-3'] .Story-text b {
  font-weight: 500;
}
.Story[data-id='2024-3'] .Story-svg {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translate(0, 0) scale(1.01);
  will-change: transform;
}
.Story[data-id='2024-3'] .Story-svg svg {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  display: block;
  margin: auto;
  height: 100%;
  width: 100%;
}
.Story[data-id='2024-3'] .Story-imgWrapper {
  margin: 0 auto;
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 1199px) {
  .Story[data-id='2024-3'] .Story-imgWrapper {
    padding: 10px;
  }
}
.Story[data-id='2024-3'] .Story-img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  pointer-events: none;
  will-change: opacity, transform;
}
.Story[data-id='2023-1'] {
  background: #fff;
  color: #1c1630;
  position: relative;
  overflow: hidden;
}
.Story[data-id='2023-1'] .Story-content {
  padding: 30px 37px 33px;
  text-align: center;
  margin: 0 auto;
}
.Story[data-id='2023-1'] .Story-text {
  font-size: 35px;
  font-weight: 500;
  line-height: 42px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-1'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
  .Story[data-id='2023-1'] .Story-text br {
    display: none;
  }
}
.Story[data-id='2023-1'] .Story-imgWrapper {
  margin: 50px auto 0;
  position: relative;
}
.Story[data-id='2023-1'] .Story-imgOld {
  position: relative;
  z-index: 3;
}
.Story[data-id='2023-1'] .Story-imgNew {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 269px;
}
.Story[data-id='2023-1'] .Story-imgNewCenter {
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 0;
  transform: scale(1.088);
  margin-left: -2px;
  z-index: 4;
}
.Story[data-id='2023-1'] .Story-img0 {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
  opacity: 1;
  will-change: opacity;
  transform: scaleX(1.06);
}
.Story[data-id='2023-1'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: 0 8px;
  will-change: transform;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -51px;
  margin-top: -135px;
}
.Story[data-id='2023-1'] .Story-img1 {
  transform: translate3d(0, 0, 0);
}
.Story[data-id='2023-1'] .Story-img2 {
  margin: 0;
  position: static;
}
.Story[data-id='2023-1'] .Story-img3 {
  transform: translate3d(0, 0, 0);
}
.Story[data-id='2023-2'] {
  background: #1c1630;
  color: #fff;
  padding: 5px 0 15px;
  position: relative;
  overflow: hidden;
  margin: 0 !important;
}
.Story[data-id='2023-2'] .Story-content {
  padding: 15px;
  text-align: left;
  margin: 0 auto;
}
.Story[data-id='2023-2'] .Story-text,
.Story[data-id='2023-2'] .Story-text2 {
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-2'] .Story-text,
  .Story[data-id='2023-2'] .Story-text2 {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-2'] .Story-inner {
  display: flex;
  flex-direction: row-reverse;
}
.Story[data-id='2023-2'] .Story-text {
  font-weight: 500;
}
.Story[data-id='2023-2'] .Story-imgWrapper {
  flex-shrink: 0;
  width: 137px;
}
.Story[data-id='2023-2'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto auto 0;
  max-width: 100%;
}
.Story[data-id='2023-3'] {
  background: #e3e3e3;
  color: #1c1630;
  position: relative;
  overflow: hidden;
  margin: 0 !important;
}
.Story[data-id='2023-3'] .Story-circle {
  border-radius: 100%;
  position: absolute;
  width: calc(425 / 375 * 100%);
  z-index: 1;
}
.Story[data-id='2023-3'] .Story-circle::before {
  display: block;
  content: "";
  padding-top: 100%;
  width: 100%;
}
.Story[data-id='2023-3'] .Story-circle--blue {
  background: #0081f9;
  top: calc(-52 / 364 * 100%);
  left: calc(-76 / 364 * 100%);
  z-index: 1;
}
.Story[data-id='2023-3'] .Story-circle--pink {
  background: #fd4575;
  top: calc(-100 / 425 * 100%);
  right: calc(-217 / 425 * 100%);
  z-index: 2;
}
.Story[data-id='2023-3'] .Story-circle--yellow {
  background: #ffdc27;
  bottom: calc(-148 / 425 * 100%);
  left: calc(-38 / 425 * 100%);
  z-index: 3;
}
.Story[data-id='2023-3'] .Story-inner {
  position: relative;
  width: 100%;
  z-index: 4;
}
.Story[data-id='2023-3'] .Story-content {
  padding: 0 30px 30px;
  position: relative;
  text-align: left;
  margin: 0 auto;
  z-index: 5;
}
.Story[data-id='2023-3'] .Story-text,
.Story[data-id='2023-3'] .Story-text2 {
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-3'] .Story-text,
  .Story[data-id='2023-3'] .Story-text2 {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-3'] .Story-text {
  font-weight: 500;
  margin-bottom: 10px;
}
.Story[data-id='2023-3'] .Story-imgWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px auto 0;
  max-width: calc(335 / 375 * 100%);
}
.Story[data-id='2023-3'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  max-width: 100%;
}
.Story[data-id='2023-4'] {
  background: #4b9a4a;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin-top: 0 !important;
}
.Story[data-id='2023-4'] .Story-content {
  padding: 20px 15px;
  text-align: center;
  margin: 0 auto;
  height: 100%;
}
.Story[data-id='2023-4'] .Story-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-4'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-4'] .Story-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 50px auto 10px;
  padding: 0;
  width: 100%;
}
.Story[data-id='2023-4'] .Story-listItem {
  margin: 0 auto 40px;
  width: 50%;
}
.Story[data-id='2023-4'] .Story-listItemText {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 20px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-4'] .Story-listItemText {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-4'] .Story-listItemImg {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  margin: auto;
}
.Story[data-id='2023-5'] {
  background: #0d3766;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin-top: 0 !important;
}
.Story[data-id='2023-5'] .Story-content {
  padding: 20px 15px;
  text-align: left;
  margin: auto;
}
.Story[data-id='2023-5'] .Story-contentFigure {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto 40px;
  padding: 8px 0 0;
}
.Story[data-id='2023-5'] .Story-imgLeft {
  position: relative;
  transform: translate3d(80px, 0, 0) rotate(20deg);
  will-change: transform;
  z-index: 1;
}
.Story[data-id='2023-5'] .Story-imgRight {
  position: relative;
  transform: translate3d(-94px, 0, 0) rotate(-20deg);
  will-change: transform;
}
.Story[data-id='2023-5'] .Story-contentCol {
  position: relative;
  margin-bottom: 30px;
}
.Story[data-id='2023-5'] .Story-contentCol:last-of-type {
  margin-top: 60px;
}
.Story[data-id='2023-5'] .Story-imgWrapper {
  position: relative;
}
.Story[data-id='2023-5'] .Story-imgWrapper--rzd {
  left: -105px;
}
.Story[data-id='2023-5'] .Story-imgWrapper--aeroflot {
  margin-left: auto;
  right: -105px;
}
.Story[data-id='2023-5'] .Story-img {
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  max-width: 100%;
}
.Story[data-id='2023-5'] .Story-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 30px;
  max-width: 340px;
}
@media (max-width: 1199px) {
  .Story[data-id='2023-5'] .Story-text {
    font-size: 16px;
    line-height: 19px;
  }
}
.Story[data-id='2023-5'] .Story-text--right {
  text-align: right;
  margin-left: auto;
}
.Story[data-id='2022-1'] {
  background: #e7a742;
  color: #fff;
  text-align: center;
  width: 100%;
}
.Story[data-id='2022-1'] .Story-inner {
  display: flex;
  flex-direction: column-reverse;
}
.Story[data-id='2022-1'] .Story-imgWrapper {
  padding-left: 34px;
  margin-bottom: 25px;
  height: 305px;
}
.Story[data-id='2022-1'] .Story-imgWrapper img {
  margin: auto;
  max-height: 100%;
}
.Story[data-id='2022-1'] .Story-content {
  padding: 15px;
}
.Story[data-id='2022-1'] .Story-text {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  max-width: 280px;
  margin: 0 auto;
}
.Story[data-id='2022-2'] {
  background: #000;
  color: #fff;
  margin-top: 0 !important;
}
.Story[data-id='2022-2'] .Story-inner {
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-start;
  justify-content: space-between;
}
.Story[data-id='2022-2'] .Story-imgWrapper {
  flex-shrink: 0;
  width: calc(221 / 375 * 100%);
}
.Story[data-id='2022-2'] .Story-imgWrapper img {
  max-width: 100%;
}
.Story[data-id='2022-2'] .Story-content {
  padding: 15px;
  position: relative;
}
.Story[data-id='2022-2'] .Story-text {
  font-size: 25px;
  line-height: 30px;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 235px;
  z-index: 1;
}
.Story[data-id='2022-2'] .Story-text b {
  display: block;
  font-weight: 600;
}
.Story[data-id='2022-3'] {
  color: #1c1630;
  font-size: 16px;
  line-height: 19px;
  margin-top: 0 !important;
}
.Story[data-id='2022-3'] .Story-imagesWrapper {
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 166px;
  overflow: hidden;
  padding: 0 24px;
}
.Story[data-id='2022-3'] .Story-imgWrapper {
  position: relative;
  top: -74px;
}
.Story[data-id='2022-3'] .Story-imgWrapper2 {
  position: relative;
  top: 25px;
}
.Story[data-id='2022-3'] .Story-imgWrapper3 {
  position: relative;
  top: -55px;
}
.Story[data-id='2022-3'] .Story-content {
  padding: 15px;
  position: relative;
}
.Story[data-id='2022-3'] .Story-text {
  font-weight: 500;
}
.Story[data-id='2022-3'] .Story-text2 {
  margin-top: 10px;
}
.Story[data-id='2022-4'] {
  background: #002d5e url('/static/img/front/history/2022-bavaria-bg.png') no-repeat 50% 50%;
  background-size: cover;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  line-height: calc(30 / 25);
  margin-top: 0 !important;
}
.Story[data-id='2022-4'] .Story-inner {
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-start;
  justify-content: space-between;
  padding: 15px 15px 0;
}
.Story[data-id='2022-4'] .Story-imgWrapper {
  flex-shrink: 0;
  display: flex;
  width: 52%;
  width: calc(196 / 375 * 100%);
}
.Story[data-id='2022-4'] .Story-imgWrapper img {
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
  max-height: 100%;
  max-width: 100%;
  aspect-ratio: 0.57664234;
}
.Story[data-id='2022-4'] .Story-content {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 200px;
  z-index: 1;
}
.Story[data-id='2022-4'] .Story-text2 {
  font-weight: 500;
}
.Story[data-id='2022-5'] {
  background: #f1e5d4;
  color: #211714;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(19 / 16);
  margin-top: 0 !important;
  width: 100%;
}
.Story[data-id='2022-5'] .Story-inner {
  display: flex;
  flex-direction: row;
}
.Story[data-id='2022-5'] .Story-imgWrapper {
  overflow: hidden;
  margin-right: 10px;
  height: 187px;
}
.Story[data-id='2022-5'] .Story-imgWrapper img {
  max-width: 100%;
}
.Story[data-id='2022-5'] .Story-content {
  margin-top: auto;
  margin-bottom: 20px;
}
.Story[data-id='2022-5'] .Story-text {
  font-weight: 500;
  margin-bottom: 10px;
}
.Story[data-id='2022-6'] {
  background: #bb1f1f;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(19 / 16);
  margin-top: 0 !important;
}
.Story[data-id='2022-6'] .Story-inner {
  display: flex;
  flex-direction: column-reverse;
}
.Story[data-id='2022-6'] .Story-content {
  padding: 20px 15px;
}
.Story[data-id='2022-6'] .Story-text {
  font-weight: 500;
  letter-spacing: -0.01em;
}
.Story[data-id='2022-6'] .Story-text2 {
  margin-top: 10px;
}
.Story[data-id='2022-6'] .Story-imagesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 20px;
}
.Story[data-id='2022-6'] .Story-imgWrapper,
.Story[data-id='2022-6'] .Story-imgWrapper2,
.Story[data-id='2022-6'] .Story-imgWrapper3 {
  position: relative;
  height: 200px;
  width: 112px;
}
.Story[data-id='2022-6'] .Story-imgWrapper img,
.Story[data-id='2022-6'] .Story-imgWrapper2 img,
.Story[data-id='2022-6'] .Story-imgWrapper3 img {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, 0) scale(0.8);
  transition: opacity 0.3s, transform 0.3s;
}
.Story[data-id='2022-6'].isAnimated-1 .Story-imgWrapper img:nth-child(1) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-1 .Story-imgWrapper2 img:nth-child(1) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-1 .Story-imgWrapper3 img:nth-child(1) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-2 .Story-imgWrapper img:nth-child(2) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-2 .Story-imgWrapper2 img:nth-child(2) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-2 .Story-imgWrapper3 img:nth-child(2) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-3 .Story-imgWrapper img:nth-child(3) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-3 .Story-imgWrapper2 img:nth-child(3) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2022-6'].isAnimated-3 .Story-imgWrapper3 img:nth-child(3) {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
.Story[data-id='2021-1'],
.Story[data-id='2021-2'],
.Story[data-id='2021-3'] {
  border-bottom: 1px solid #dcdcdc;
  overflow: hidden;
  width: 100%;
}
.Story[data-id='2021-1'] .Story-imgWrapper,
.Story[data-id='2021-2'] .Story-imgWrapper,
.Story[data-id='2021-3'] .Story-imgWrapper {
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2021-1'] .Story-img,
.Story[data-id='2021-2'] .Story-img,
.Story[data-id='2021-3'] .Story-img {
  display: block;
  max-width: 100%;
}
.Story[data-id='2021-1'] .Story-content,
.Story[data-id='2021-2'] .Story-content,
.Story[data-id='2021-3'] .Story-content {
  background: #fff;
  padding: 15px;
}
.Story[data-id='2021-1'] .Story-text,
.Story[data-id='2021-2'] .Story-text,
.Story[data-id='2021-3'] .Story-text {
  color: #1c1630;
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
}
.Story[data-id='2021-1'] .Story-imagesWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2021-1'] .Story-imgWrapper {
  position: absolute;
  top: 15px;
  right: 15px;
}
.Story[data-id='2021-1'] .Story-imgWrapper2 {
  position: absolute;
  top: 15px;
  left: 15px;
}
.Story[data-id='2021-list'] {
  background: #1c1630;
  border-bottom: 1px solid #dcdcdc;
  color: #fff;
  padding: 15px 0;
  text-align: center;
}
.Story[data-id='2021-list'] .Story-title {
  display: none;
}
.Story[data-id='2021-list'] .Story-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  text-align: center;
}
.Story[data-id='2021-list'] .Story-list .Story-listItemImageAward {
  transform: scale(1);
  opacity: 1;
}
.Story[data-id='2021-list'] .Story-list .Story-listItemImageBottle {
  transform: scale(0.8);
  opacity: 0;
}
.Story[data-id='2021-list'] .Story-list.isAnimated .Story-listItemImageAward {
  transform: scale(0.8);
  opacity: 0;
}
.Story[data-id='2021-list'] .Story-list.isAnimated .Story-listItemImageBottle {
  transform: scale(1);
  opacity: 1;
}
.Story[data-id='2021-list'] .Story-listItemImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto 10px;
  height: 204px;
  width: 100%;
}
.Story[data-id='2021-list'] .Story-listItemImage img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  pointer-events: none;
  transition: transform 0.3s ease,
                opacity 0.3s ease;
}
.Story[data-id='2021-list'] .Story-listItem {
  padding: 15px;
  margin-bottom: 10px;
}
.Story[data-id='2021-2'] .Story-img {
  margin-top: -24%;
}
.Story[data-id='2021-3'] .Story-img {
  display: block;
  max-width: initial;
  animation: slide infinite 9s linear;
  transform: translate(0, 0);
}
@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-560px, 0);
  }
}
.Story[data-id='2020-5'] {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2020-5'].isAnimated .Story-img {
  -webkit-filter: blur(0);
          filter: blur(0);
}
.Story[data-id='2020-5'] .Story-content {
  padding: 15px;
  background: #fff;
}
.Story[data-id='2020-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2020-5'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2020-5'] .Story-imgWrapper2 {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 30px;
  left: 30px;
  z-index: 1;
}
.Story[data-id='2020-5'] .Story-img,
.Story[data-id='2020-5'] .Story-img2 {
  height: 100%;
  width: 100%;
}
.Story[data-id='2020-5'] .Story-img {
  -webkit-filter: blur(40px);
          filter: blur(40px);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  transition: -webkit-filter .15s;
  transition: filter .15s;
  transition: filter .15s, -webkit-filter .15s;
  transform: translate3d(0, 0, 0);
  will-change: filter;
}
.Story[data-id='2020-4'] {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2020-4'].isAnimated .Story-svgList svg:nth-child(1) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-2 .Story-svgList svg:nth-child(2) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-3 .Story-svgList svg:nth-child(3) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-4 .Story-svgList svg:nth-child(4) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-5 .Story-svgList svg:nth-child(5) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-6 .Story-svgList svg:nth-child(6) {
  opacity: 1;
}
.Story[data-id='2020-4'].isAnimated-7 .Story-svgList svg:nth-child(7) {
  opacity: 1;
}
.Story[data-id='2020-4'] .Story-content {
  padding: 15px;
  background: #fff;
}
.Story[data-id='2020-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2020-4'] .Story-svgList {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 0 10px;
}
.Story[data-id='2020-4'] .Story-svgList svg {
  height: 75px;
  width: 35px;
  opacity: 0;
  transition: opacity 0.15s;
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(2),
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(4) {
  height: 41px;
  width: 41px;
  margin: 0 8px 15px -3px;
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(4) {
  margin-right: -3px;
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(5) {
  transform: rotate(180deg);
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(6) {
  height: 38px;
  width: 38px;
  margin: 0 34px 16px;
}
.Story[data-id='2020-4'] .Story-svgList svg:nth-child(7) {
  height: 85px;
  width: 55px;
}
.Story[data-id='2020-3'] {
  width: 100%;
  background: #1c1630;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2020-3'] .Story-content {
  padding: 15px;
  background: #fff;
}
.Story[data-id='2020-3'] .Story-textMobile {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2020-3'] .Story-imagesWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2020-3'] .Story-imgWrapper,
.Story[data-id='2020-3'] .Story-imgWrapper2 {
  position: absolute;
  height: 260px;
  width: 96px;
}
.Story[data-id='2020-3'] .Story-imgWrapper {
  top: 0;
  left: 20px;
}
.Story[data-id='2020-3'] .Story-imgWrapper2 {
  bottom: 0;
  right: 20px;
}
.Story[data-id='2020-3'] .Story-img,
.Story[data-id='2020-3'] .Story-img2 {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2020-3'] .Story-img {
  top: 20px;
}
.Story[data-id='2020-3'] .Story-img2 {
  bottom: 20px;
}
.Story[data-id='2020-2'] {
  background: #bda470;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2020-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #fff;
  z-index: 3;
}
.Story[data-id='2020-2'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2020-2'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-top: -120px;
  margin-left: -120px;
}
.Story[data-id='2020-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2020-1'] {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2020-1'] .Story-content {
  padding: 15px;
  background: #fff;
}
.Story[data-id='2020-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2020-1'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2020-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 150px;
  left: 0;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2020-1'] .Story-imgWrapper2 {
  display: none;
}
.Story[data-id='2019-4'] {
  width: 100%;
  padding-top: 300px;
  overflow: hidden;
}
.Story[data-id='2019-4'].isAnimated .Story-imgWrapper {
  opacity: 0;
  transform: scale(0.8);
  transition: transform .3s, opacity .3s;
}
.Story[data-id='2019-4'].isAnimated .Story-imgWrapper2 {
  opacity: 1;
  transform: scale(1);
  transition: transform .3s .6s, opacity .3s .6s;
}
.Story[data-id='2019-4'] .Story-content {
  padding: 15px;
  background: #ffffff;
}
.Story[data-id='2019-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2019-4'] .Story-imgWrapper {
  position: absolute;
  left: 0;
  top: 25px;
  right: 0;
  width: 240px;
  height: 240px;
  margin: 0 auto;
  transition: transform .3s .6s, opacity .3s .6s;
}
.Story[data-id='2019-4'] .Story-imgWrapper2 {
  position: absolute;
  left: 0;
  top: 25px;
  right: 0;
  width: 240px;
  height: 240px;
  margin: 0 auto;
  opacity: 0;
  transform: scale(0.8);
  transition: transform .3s, opacity .3s;
}
.Story[data-id='2019-4'] .Story-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2019-3'] {
  position: relative;
  padding-top: 300px;
  overflow: hidden;
}
.Story[data-id='2019-3'].isAnimated .Story-img,
.Story[data-id='2019-3'].isAnimated .Story-img2,
.Story[data-id='2019-3'].isAnimated .Story-img3,
.Story[data-id='2019-3'].isAnimated .Story-img4 {
  opacity: 1;
}
.Story[data-id='2019-3'] .Story-content {
  padding: 15px;
  background: #ffffff;
}
.Story[data-id='2019-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2019-3'] .Story-imgWrapper {
  position: absolute;
  top: 0;
  right: 90px;
  width: 370px;
  height: 300px;
}
.Story[data-id='2019-3'] .Story-imgWrapper2 {
  position: absolute;
  left: 105px;
  top: 0;
  width: 300px;
  height: 230px;
}
.Story[data-id='2019-3'] .Story-imgWrapper3 {
  position: absolute;
  left: 48px;
  top: 0;
  right: 54px;
  height: 300px;
}
.Story[data-id='2019-3'] .Story-imgWrapper4 {
  position: absolute;
  left: 48px;
  top: 0;
  right: 54px;
  height: 300px;
}
.Story[data-id='2019-3'] .Story-img,
.Story[data-id='2019-3'] .Story-img2,
.Story[data-id='2019-3'] .Story-img3,
.Story[data-id='2019-3'] .Story-img4 {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
  -o-object-position: center;
     object-position: center;
  opacity: 0;
}
.Story[data-id='2019-3'] .Story-img {
  transition: opacity .15s;
}
.Story[data-id='2019-3'] .Story-img2 {
  transition: opacity .15s .15s;
}
.Story[data-id='2019-3'] .Story-img3 {
  transition: opacity .15s .3s;
}
.Story[data-id='2019-3'] .Story-img4 {
  transition: opacity .15s .45s;
}
.Story[data-id='2019-2'] .Story-content {
  padding: 15px;
  background: #ffffff;
}
.Story[data-id='2019-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2019-2'] .Story-imagesWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2019-2'] .Story-images {
  position: absolute;
  left: 0;
  top: -60px;
  right: 0;
  width: 170px;
  margin: 0 auto;
  transform: rotate(61.54deg);
}
.Story[data-id='2019-2'] .Story-images:after {
  content: '';
  display: block;
  padding-bottom: 270%;
}
.Story[data-id='2019-2'] .Story-imgWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.Story[data-id='2019-2'] .Story-imgWrapper:first-child .Story-imgMask {
  height: 100%;
}
.Story[data-id='2019-2'] .Story-img {
  width: 100%;
  height: 100%;
}
.Story[data-id='2019-2'] .Story-imgMask {
  height: 0;
}
.Story[data-id='2019-1'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2019-1'] .Story-content {
  padding: 15px;
  background: #ffffff;
}
.Story[data-id='2019-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2019-1'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2019-1'] .Story-img {
  position: absolute;
  height: 400px;
  width: 100%;
  left: 0;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2018-1'] {
  background: #ffffff;
  padding-top: 300px;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2018-1'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2018-1'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2018-1'] .Story-animation {
  position: absolute;
  top: -4%;
  left: 0;
  width: 100%;
}
.Story[data-id='2018-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2018-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2018-2'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2018-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2018-2'] .Story-imgWrapper {
  position: relative;
  padding-top: 15px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2018-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2018-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2018-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2018-3'] {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2018-3'] .Story-content {
  padding: 15px;
  background: #ffffff;
}
.Story[data-id='2018-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2018-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2018-3'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2018-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 150px;
  left: 0;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2017-1'] {
  width: 100%;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2017-1'] .Story-imgWrapper {
  text-align: center;
  margin-bottom: 10px;
}
.Story[data-id='2017-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2017-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2017-2'] {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2017-2'] .Story-img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 151px;
  height: 144px;
  margin-left: -75px;
  margin-top: -72px;
}
.Story[data-id='2017-2'] .Story-imgWrapper {
  position: relative;
  height: 300px;
}
.Story[data-id='2017-2'] .Story-content {
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.Story[data-id='2017-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2017-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2017-3'] {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2017-3'] .Story-content {
  padding: 15px;
  background: #ffffff;
}
.Story[data-id='2017-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2017-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2017-3'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2017-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 150px;
  left: 0;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2016-1'] {
  width: 100%;
  background: #1c1630;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2016-1'] .Story-content {
  width: 100%;
  background: #ffffff;
  padding: 15px;
}
.Story[data-id='2016-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2016-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2016-1'] .Story-imgWrapperOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.Story[data-id='2016-1'] .Story-imgWrapperInner {
  position: relative;
  width: 300px;
  padding: 10%;
}
.Story[data-id='2016-1'] .Story-imgWrapperInner:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.Story[data-id='2016-1'] .Story-outerCircle {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #2c2448;
  border-radius: 50%;
}
.Story[data-id='2016-1'] .Story-innerCircle {
  content: '';
  position: absolute;
  height: 64%;
  width: 64%;
  top: 18%;
  left: 18%;
  background: #e6102d;
  border-radius: 50%;
}
.Story[data-id='2016-1'] .Story-contentCircle {
  position: absolute;
  height: 48%;
  width: 48%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 30px;
  overflow: hidden;
  background: #fff;
  transition: transform 0.1s ease-out;
  z-index: 1;
  will-change: transform;
  border-radius: 50%;
}
.Story[data-id='2016-1'] .Story-circleImage {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2016-2'] {
  padding: 15px;
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2016-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2016-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2016-3'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2016-3'] .Story-content {
  position: relative;
  padding: 15px;
  background: #fff;
  z-index: 3;
}
.Story[data-id='2016-3'] .Story-imgWrapper {
  position: relative;
  height: 300px;
}
.Story[data-id='2016-3'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
  -o-object-fit: contain;
     object-fit: contain;
}
.Story[data-id='2016-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2016-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2015-1'] {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2015-1'] .Story-content {
  padding: 15px;
  background: #ffffff;
}
.Story[data-id='2015-1'] .Story-imgWrapper {
  height: 300px;
  position: relative;
  width: 100%;
}
.Story[data-id='2015-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2015-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2015-2'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2015-2'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2015-2'] .Story-imgWrapper {
  position: relative;
  height: 300px;
}
.Story[data-id='2015-2'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
}
.Story[data-id='2015-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2015-3'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2015-3'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2015-3'] .Story-imgWrapper {
  position: relative;
  height: 300px;
}
.Story[data-id='2015-3'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
}
.Story[data-id='2015-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2015-4'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2015-4'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2015-4'] .Story-imgWrapper {
  position: relative;
  height: 300px;
}
.Story[data-id='2015-4'] .Story-img {
  position: absolute;
  height: 136px;
  width: 250px;
  top: 50%;
  left: 50%;
  margin-top: -68px;
  margin-left: -125px;
}
.Story[data-id='2015-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2015-5'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2015-5'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2015-5'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2015-5'] .Story-img {
  position: absolute;
  height: 250px;
  width: 90px;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -45px;
}
.Story[data-id='2015-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2015-6'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2015-6'] .Story-content {
  position: relative;
  padding: 15px;
  background: #fff;
  z-index: 3;
}
.Story[data-id='2015-6'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2015-6'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.Story[data-id='2015-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-6'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2015-7'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2015-7'] .Story-content {
  position: relative;
  padding: 15px;
  background: #fff;
  z-index: 3;
}
.Story[data-id='2015-7'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2015-7'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}
.Story[data-id='2015-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2015-7'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-1'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-1'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2014-1'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2014-1'] .Story-img {
  position: absolute;
  height: 240px;
  width: 232px;
  top: 50%;
  left: 50%;
  margin-left: -116px;
  margin-top: -120px;
}
.Story[data-id='2014-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-2'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-2'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2014-2'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2014-2'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
}
.Story[data-id='2014-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-3'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-3'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2014-3'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2014-3'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
}
.Story[data-id='2014-3'] .Story-imgWrapper2 {
  position: absolute;
  height: 152px;
  top: 5px;
  bottom: 0;
  left: 128px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}
.Story[data-id='2014-3'] .Story-img2 {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-4'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-4'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2014-4'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2014-4'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
}
.Story[data-id='2014-4'] .Story-imgWrapper2 {
  position: absolute;
  height: 152px;
  top: 5px;
  bottom: 0;
  left: 128px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
}
.Story[data-id='2014-4'] .Story-img2 {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2014-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-5'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-5'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2014-5'] .Story-imgWrapper {
  position: relative;
  padding-top: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2014-5'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2014-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-6'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-6'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2014-6'] .Story-imgWrapper {
  position: relative;
  padding-top: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2014-6'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2014-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-6'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-7'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-7'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2014-7'] .Story-inner {
  padding-top: 15px;
}
.Story[data-id='2014-7'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  height: 300px;
}
.Story[data-id='2014-7'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2014-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-7'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-8'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-8'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2014-8'] .Story-inner {
  padding-top: 15px;
}
.Story[data-id='2014-8'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  height: 300px;
}
.Story[data-id='2014-8'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2014-8'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-8'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-9'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-9'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2014-9'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  margin-bottom: 30px;
  height: 300px;
}
.Story[data-id='2014-9'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2014-9'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-9'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2014-10'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2014-10'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2014-10'] .Story-inner {
  padding-top: 15px;
}
.Story[data-id='2014-10'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  height: 300px;
}
.Story[data-id='2014-10'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2014-10'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2014-10'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2013-1'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2013-1'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2013-1'] .Story-imgWrapper {
  position: relative;
  padding-top: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2013-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 400px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2013-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2013-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2013-1'] .Story-text2 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: #616166;
}
.Story[data-id='2013-2'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2013-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2013-2'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2013-2'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2013-2'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2013-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2013-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2013-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2013-3'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2013-3'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2013-3'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2013-3'] .Story-img {
  position: absolute;
  height: 240px;
  width: 232px;
  top: 50%;
  left: 50%;
  margin-left: -116px;
  margin-top: -120px;
}
.Story[data-id='2013-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2013-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2013-4'] {
  background: #fff;
  overflow: hidden;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2013-4'] .Story-inner {
  display: flex;
  position: relative;
  height: 100%;
  border: 1px solid #ebebf5;
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.Story[data-id='2013-4'] .Story-content {
  position: relative;
  padding: 315px 15px 15px;
}
.Story[data-id='2013-4'] .Story-imgWrapper {
  position: absolute;
  height: 300px;
  top: 5px;
  left: 50%;
  margin-left: -120px;
  z-index: 2;
}
.Story[data-id='2013-4'] .Story-img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-4'] .Story-imgWrapper2 {
  position: absolute;
  height: 300px;
  top: 5px;
  left: 50%;
  margin-left: -20px;
  z-index: 1;
}
.Story[data-id='2013-4'] .Story-img2 {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2013-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2013-5'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2013-5'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2013-5'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2013-5'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
}
.Story[data-id='2013-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2013-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2013-6'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2013-6'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2013-6'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2013-6'] .Story-img {
  position: absolute;
  height: 240px;
  width: 240px;
  top: 50%;
  left: 50%;
  margin-left: -120px;
  margin-top: -120px;
}
.Story[data-id='2013-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2013-6'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2013-7'] {
  overflow: hidden;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2013-7'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2013-7'] .Story-imgWrapper {
  position: relative;
  height: 300px;
}
.Story[data-id='2013-7'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: right;
     object-position: right;
}
.Story[data-id='2013-7'] .Story-img2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 70%;
  right: -70%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-7'] .Story-img3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 140%;
  right: -140%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.Story[data-id='2013-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2013-7'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2012-1'] {
  display: flex;
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2012-1'] .Story-img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 151px;
  height: 144px;
  margin-left: -75px;
  margin-top: -72px;
}
.Story[data-id='2012-1'] .Story-imgWrapper {
  flex: 1 1;
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2012-1'] .Story-inner {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.Story[data-id='2012-1'] .Story-content {
  background: #fff;
  padding: 15px;
}
.Story[data-id='2012-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2012-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2012-2'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2012-2'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2012-2'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2012-2'] .Story-img {
  position: absolute;
  height: 136px;
  width: 250px;
  top: 50%;
  left: 50%;
  margin-top: -68px;
  margin-left: -125px;
}
.Story[data-id='2012-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2012-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2012-3'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2012-3'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2012-3'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2012-3'] .Story-img {
  position: absolute;
  height: 259px;
  width: 256px;
  top: 50%;
  left: 50%;
  margin-top: -129px;
  margin-left: -128px;
}
.Story[data-id='2012-3'] .Story-circle {
  position: absolute;
  height: 140px;
  width: 140px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(2, 161, 224, 0.14);
  border-radius: 50%;
}
.Story[data-id='2012-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2012-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2012-4'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2012-4'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2012-4'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2012-4'] .Story-img {
  position: absolute;
  height: 238px;
  width: 245px;
  top: 50%;
  left: 50%;
  margin-top: -119px;
  margin-left: -122px;
}
.Story[data-id='2012-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2012-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2012-5'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2012-5'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2012-5'] .Story-imgWrapper {
  position: relative;
  height: 300px;
  z-index: 3;
}
.Story[data-id='2012-5'] .Story-img {
  position: absolute;
  height: 134px;
  width: 131px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -170px;
}
.Story[data-id='2012-5'] .Story-imgWrapper2 {
  position: absolute;
  height: 300px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.Story[data-id='2012-5'] .Story-img2 {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2012-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2012-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2012-6'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2012-6'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
@media (max-width: 1199px) {
  .Story[data-id='2012-6'] .Story-content {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .Story[data-id='2012-6'] .Story-content {
    padding-bottom: 30px;
  }
}
.Story[data-id='2012-6'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2012-6'] .Story-img {
  position: absolute;
  height: 180px;
  width: 180px;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  margin-left: -90px;
}
.Story[data-id='2012-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2012-6'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2011-1'] {
  background: #fff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2011-1'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2011-1'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2011-1'] .Story-img {
  position: absolute;
  height: 250px;
  width: 90px;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -45px;
}
.Story[data-id='2011-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2011-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2011-2'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2011-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2011-2'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
  z-index: 4;
}
.Story[data-id='2011-2'] .Story-img {
  position: absolute;
  height: 114px;
  width: 73px;
  top: 50%;
  left: 50%;
  margin-left: 95px;
  margin-top: -110px;
}
.Story[data-id='2011-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2011-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2011-2'] .Story-text2 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 500;
  color: #e6102d;
  position: absolute;
  max-width: 270px;
  bottom: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 79px;
  padding-top: 25px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
  border-radius: 9px;
}
.Story[data-id='2011-3'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2011-3'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2011-3'] .Story-imgWrapper {
  position: relative;
  padding-top: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2011-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 250px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2011-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2011-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2011-4'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2011-4'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2011-4'] .Story-imgWrapper {
  position: relative;
  height: 300px;
}
.Story[data-id='2011-4'] .Story-img {
  position: absolute;
  height: 174px;
  width: 174px;
  top: 50%;
  left: 50%;
  margin-left: -87px;
  margin-top: -87px;
}
.Story[data-id='2011-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2011-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2011-5'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2011-5'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2011-5'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2011-5'] .Story-img {
  position: absolute;
  height: 180px;
  width: 180px;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  margin-left: -90px;
}
.Story[data-id='2011-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2011-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2010-1'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2010-1'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2010-1'] .Story-inner {
  padding-top: 15px;
}
.Story[data-id='2010-1'] .Story-imgWrapper {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2010-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2010-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2010-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2010-2'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2010-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2010-2'] .Story-inner {
  padding-top: 15px;
}
.Story[data-id='2010-2'] .Story-imgWrapper {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2010-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2010-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2010-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2010-3'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2010-3'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2010-3'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2010-3'] .Story-img {
  position: absolute;
  height: 135px;
  width: 224px;
  top: 50%;
  left: 50%;
  margin-left: -112px;
  margin-top: -67px;
}
.Story[data-id='2010-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2010-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2010-4'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
  padding-top: 15px;
}
.Story[data-id='2010-4'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2010-4'] .Story-imgWrapper {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2010-4'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2010-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2010-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2010-5'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2010-5'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2010-5'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
  z-index: 2;
}
.Story[data-id='2010-5'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2010-5'] .Story-imgWrapper2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.Story[data-id='2010-5'] .Story-img2 {
  position: absolute;
  width: 123%;
  bottom: 22px;
  left: 0;
  right: 0;
}
.Story[data-id='2010-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2010-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2010-6'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2010-6'] .Story-content {
  position: relative;
  padding: 15px;
  z-index: 3;
}
.Story[data-id='2010-6'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2010-6'] .Story-img {
  position: absolute;
  height: 112px;
  width: 101px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -56px;
}
.Story[data-id='2010-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2010-6'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2009-1'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2009-1'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2009-1'] .Story-imgWrapper {
  position: relative;
  padding-top: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2009-1'] .Story-img {
  position: absolute;
  height: 201px;
  width: 324px;
  top: 50%;
  left: 50%;
  margin-left: -162px;
  margin-top: -100px;
}
.Story[data-id='2009-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2009-2'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2009-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2009-2'] .Story-imgWrapper {
  position: relative;
  padding-top: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2009-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2009-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2009-3'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2009-3'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2009-3'] .Story-imgWrapper {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2009-3'] .Story-inner {
  padding-top: 15px;
}
.Story[data-id='2009-3'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2009-3'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-3'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2009-4'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2009-4'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2009-4'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2009-4'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.Story[data-id='2009-4'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-4'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2009-5'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2009-5'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2009-5'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2009-5'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2009-5'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-5'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2009-6'] {
  background: #ffffff;
  padding-top: 300px;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2009-6'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2009-6'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2009-6'] .Story-animation {
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  width: 100%;
}
.Story[data-id='2009-6'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-6'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2009-7'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2009-7'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2009-7'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2009-7'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 85%;
     object-position: 85%;
}
.Story[data-id='2009-7'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2009-7'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2008-1'] {
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2008-1'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2008-1'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2008-1'] .Story-img {
  position: absolute;
  height: 190px;
  width: 312px;
  top: 50%;
  left: 50%;
  margin-left: -156px;
  margin-top: -110px;
}
.Story[data-id='2008-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2008-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2008-2'] {
  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2008-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2008-2'] .Story-inner {
  padding-top: 15px;
}
.Story[data-id='2008-2'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  height: 300px;
}
.Story[data-id='2008-2'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2008-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2008-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2007-1'] {
  background: #ffffff !important;
  padding-top: 300px;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2007-1'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2007-1'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2007-1'] .Story-img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
}
.Story[data-id='2007-1'] .Story-video {
  position: absolute;
  height: 300px;
  width: 100%;
  top: 0;
  left: 0;
  background: #000;
}
.Story[data-id='2007-1'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2007-1'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story[data-id='2007-2'] {
  background: #ffffff;
  padding-top: 300px;
  border-bottom: 1px solid #dcdcdc;
}
.Story[data-id='2007-2'] .Story-content {
  position: relative;
  padding: 15px;
  background: #ffffff;
  z-index: 3;
}
.Story[data-id='2007-2'] .Story-imgWrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.Story[data-id='2007-2'] .Story-animation {
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  width: 100%;
}
.Story[data-id='2007-2'] .Story-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #e6102d;
  margin-left: -1px;
}
.Story[data-id='2007-2'] .Story-text {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1c1630;
}
.Story {
  position: relative;
}
.Story + .Story {
  margin-top: 10px;
}
