.LegalMenuTop {
  background: #fff;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  min-height: 46px;
  padding: 0 15px;
  overflow-y: auto;
}
.LegalMenuTop-item {
  color: #1c1630;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.035em;
  line-height: 17px;
  width: 45%;
  transition: color 0.3s ease;
}
.LegalMenuTop-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.LegalMenuTop-item.isActive {
  color: #e6102d;
}
.LegalMenuTop-item:first-of-type {
  transform: translateX(2px);
}
.LegalMenuTop--legal,
.LegalMenuTop--partners {
  border-bottom: 1px solid #ebebf6;
}
.LegalMenuTop--legal .LegalMenuTop-item,
.LegalMenuTop--partners .LegalMenuTop-item {
  color: #0f0c1a;
}
.LegalMenuTop--legal .LegalMenuTop-item:hover,
.LegalMenuTop--partners .LegalMenuTop-item:hover {
  color: #e6102d;
  transition: color 0.3s ease;
  cursor: pointer;
}
.LegalMenuTop--legal .LegalMenuTop-item.isActive,
.LegalMenuTop--partners .LegalMenuTop-item.isActive {
  color: #e6102d;
}
