.NotFound {
  display: flex;
  position: fixed;
  align-items: center;
  height: 100vh;
  width: 100%;
  margin-bottom: 20%;
  padding: 50px;
  background: #0f0c1a;
  z-index: 2;
}
.NotFound-content {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 38px;
  line-height: 52px;
  color: #fff;
  margin-bottom: 10%;
}
.NotFound-first {
  margin-bottom: 15px;
}
.NotFound-second {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  line-height: 40px;
  color: #e6102d;
  position: relative;
}
.NotFound-second:before {
  content: '';
  position: absolute;
  height: calc(100% + 6px);
  width: 100%;
  top: 0;
  left: 0;
  opacity: .2;
  border-bottom: 4px solid #e6102d;
  transition: opacity 0.15s ease;
  vertical-align: bottom;
}
.NotFound-second:hover {
  cursor: pointer;
}
.NotFound-second:hover:before {
  opacity: .5;
}
