.History {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 51px;
  background: #fafafc;
}
.History-menuTop {
  width: 100%;
  z-index: 1;
}
.History-contentWrapper {
  padding: 15px;
}
.History-header {
  color: #1c1630;
  width: 100%;
  padding: 70px 15px 15px;
  z-index: 1;
}
.History-subtitle {
  display: flex;
  margin-bottom: 4px;
}
.History-subtitleStroke {
  height: 19px;
  width: 109px;
  margin-right: 11px;
  border-bottom: 1px solid #e6102d;
}
.History-subtitleText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2.75px;
  text-transform: uppercase;
  color: #e6102d;
}
.History-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 22px;
}
.History-years {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.History-years-inner {
  padding: 12px 15px;
  position: relative;
  z-index: 3;
  white-space: nowrap;
  overflow: auto;
}
.History-years:after {
  content: " ";
  position: absolute;
  height: 193px;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADBCAYAAAAO/do9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAEhJREFUeNpi+P//PxMTAwMDIyrBhJeF4BIrRqzJVDQPp7eIMIA65lHRfWCCgRb+xWkyJcFEsXlostQ2j5G8pExF8xioSAAEGAAcdAV9jA4smQAAAABJRU5ErkJggg==') repeat-x;
  pointer-events: none;
}
.History-yearLink {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}
.History-yearLink + .History-yearLink {
  margin-left: 40px;
}
.History-yearLink.isActive {
  color: #e6102d;
  pointer-events: none;
}
.History-year {
  font-size: 100px;
  font-weight: 600;
  color: #1c1630;
  position: relative;
  margin: 50px 15px 2px;
}
.History-year span {
  -webkit-text-fill-color: #fafafc;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #1c1630;
}
.History-year:after {
  content: attr(data-year);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.History-year.isActive:after {
  opacity: 1;
}
.History-launch {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  background: #ffffff;
  padding: 15px;
  border-bottom: 1px solid #dcdcdc;
}
.History-news[data-year="2024"] {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.History-news[data-year="2024"] .Story[data-id="2024-1"] {
  order: 1;
}
.History-news[data-year="2024"] .Story[data-id="2024-2"] {
  order: 3;
}
.History-news[data-year="2024"] .Story[data-id="2024-3"] {
  order: 2;
}
