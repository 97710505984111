.Excursion {
  background: #fff;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  padding-bottom: 100px;
  color: #1c1630;
  overflow: hidden;
  transition: transform 0.7s ease;
  z-index: 22;
}
.Excursion--toAnimate {
  position: fixed;
  transform: translate3d(0, 100vh, 0);
}
.Excursion-close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
}
.Excursion-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 395px;
  margin-bottom: 30px;
}
.Excursion-img {
  height: 100%;
  background: #fff;
}
.Excursion-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Excursion-shareButton {
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: -98px;
  z-index: 10;
}
.Excursion-body {
  position: relative;
}
.Excursion-body:after {
  content: '';
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}
.Excursion-background {
  position: absolute;
  height: 410px;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #1c1630;
}
.Excursion-backgroundImage {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .5;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 32%;
     object-position: 32%;
}
.Excursion-subtitle {
  display: flex;
  margin-bottom: 10px;
}
.Excursion-subtitleStroke {
  height: 19px;
  width: 75px;
  margin-right: 11px;
  border-bottom: 1px solid #e6102d;
}
.Excursion-subtitleText {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 2.75px;
  color: #e6102d;
  text-transform: uppercase;
}
.Excursion-headerTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: normal;
  line-height: 34px;
  color: #fff;
  margin-bottom: 43px;
}
.Excursion-textSection {
  margin-bottom: 50px;
}
.Excursion-textSection--beforeMap {
  margin-bottom: 32px;
}
.Excursion-textSectionTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: calc(34 / 28);
  margin-bottom: 24px;
}
.Excursion-textSectionSubTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: calc(24 / 16);
  margin: 0 0 1em;
}
.Excursion-textSectionContent {
  font-size: 16px;
  line-height: calc(24 / 16);
  margin-bottom: 32px;
}
.Excursion-textSectionContent:last-child {
  margin-bottom: 0;
}
.Excursion-textSectionContent * + * {
  margin-top: 1em;
}
.Excursion-textSectionContent a {
  color: #e6102d;
}
.Excursion-textSectionContent a:hover {
  color: #cc001b;
}
.Excursion-contacts {
  font-size: 16px;
  line-height: calc(24 / 16);
  margin: 50px 0;
}
.Excursion-contactsTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: calc(34 / 28);
  margin: 0 0 24px;
}
.Excursion-contactsListItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1em;
}
.Excursion-contactsListLabel {
  margin-right: 20px;
  min-width: 107px;
}
.Excursion-contactsListContent {
  min-width: 177px;
}
.Excursion-contactsListLink {
  color: #0f0c1a;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s ease;
}
.Excursion-contactsListLink:hover {
  color: #e6102d;
}
.Excursion-contactsListIcon {
  margin-right: 4px;
  height: 18px;
  width: 18px;
}
.Excursion-contactsListIcon svg {
  display: block;
  fill: currentcolor;
  height: 100%;
  width: 100%;
  transition: fill 0.3s ease;
}
.Excursion-contactsText {
  margin-bottom: 1em;
}
.Excursion-contactsText p + p {
  margin-top: 1em;
}
.Excursion-contactsText a {
  color: #e6102d;
}
.Excursion-tour {
  margin-bottom: 32px;
}
.Excursion-tourTable {
  margin-bottom: 10px;
  width: 100%;
}
.Excursion-tourTable tr {
  border-bottom: 1px solid #dcdcdc;
}
.Excursion-tourTable tr:first-child {
  border-top: 1px solid #dcdcdc;
}
.Excursion-tourTable td,
.Excursion-tourTable th {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  padding: 10px 0;
}
.Excursion-tourTable b {
  font-weight: 500;
}
.Excursion-tourNote {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  line-height: calc(20 / 12);
}
.Excursion-buyButtonWrapper {
  margin: 50px 0 32px;
}
.Excursion-buyButtonWrapperNote {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: calc(22 / 14);
  margin: 0 0 16px;
}
.Excursion-button {
  background: #e6102d !important;
  border: none !important;
  box-shadow: none !important;
  font-family: 'Graphik LCG', Arial, sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 0 !important;
  text-align: center;
  text-shadow: none !important;
  text-transform: none !important;
  height: 40px !important;
  width: 100%;
}
.Excursion-button--black {
  background: #0f0c1a !important;
  color: #fff !important;
}
.Excursion-transportBus {
  border-bottom: 1px solid #dcdcdc;
  margin: 0 -15px 50px;
  position: relative;
  height: 101px;
}
.Excursion-transportBus svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  left: 50%;
  margin-left: -160px;
  height: 100px;
  width: 305px;
}
.Excursion-features {
  font-size: 16px;
  line-height: calc(24 / 16);
}
.Excursion-featuresList li {
  margin-bottom: 16px;
}
.Excursion-featuresText p + p {
  margin-top: 1em;
}
.Excursion-slider {
  margin: -18px -15px 45px;
}
.Excursion-map {
  font-size: 16px;
  line-height: calc(24 / 16);
  margin-bottom: 32px;
  position: relative;
}
.Excursion-map p + p {
  margin-top: 1em;
}
.Excursion-mapLegend {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 16px 0 14px;
  padding: 0;
}
.Excursion-mapLegend li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  margin-right: 40px;
}
.Excursion-mapLegendColor {
  border-radius: 4px;
  flex-shrink: 0;
  margin-right: 10px;
  position: relative;
  height: 5px;
  width: 20px;
}
.Excursion-mapLegendColor--car {
  background: #e76556;
}
.Excursion-mapLegendColor--pedestrian {
  border-radius: 0;
}
.Excursion-mapLegendColor--pedestrian:after,
.Excursion-mapLegendColor--pedestrian:before {
  background: #4359e2;
  border-radius: 4px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 5px;
  width: 8px;
}
.Excursion-mapLegendColor--pedestrian:after {
  left: auto;
  right: 0;
}
.Excursion-mapLegendColor--masstransit {
  background: #9df67b;
}
.Excursion-mapContainerWrapper {
  position: relative;
}
.Excursion-mapLoader {
  background: rgba(250, 250, 252, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}
.Excursion-mapContainer {
  margin: 24px 0 0;
  height: 211px;
}
.Excursion-asideInfoBlocks {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  margin: 32px 0;
}
.Excursion-asideInfo {
  font-size: 14px;
  font-weight: 500;
  line-height: calc(22 / 14);
  width: calc(50% - 20px);
}
.Excursion-asideInfo--row {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-basis: 100%;
  max-width: initial;
  width: 100%;
}
.Excursion-asideInfo--row .Excursion-asideInfoIcon {
  margin: 0 15px 0 0;
}
.Excursion-asideInfo:last-child {
  margin-bottom: 0;
}
.Excursion-asideInfoIcon {
  flex-shrink: 0;
  margin-bottom: 12px;
}
.Excursion-asideInfoIcon svg {
  display: block;
  height: 100%;
  width: 100%;
}
.Excursion-asideInfoIcon--docs {
  height: 58px;
  width: 40px;
}
.Excursion-asideInfoIcon--docs svg {
  fill: #fff;
}
.Excursion-asideInfoIcon--shoot {
  height: 38px;
  width: 58px;
}
.Excursion-asideInfoIcon--time {
  height: 43px;
  width: 43px;
}
.Excursion-asideInfoIcon--route {
  height: 40px;
  width: 62px;
}
.Excursion-asideInfoTitle {
  color: #e6102d;
  font-size: 38px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 12px;
}
.Excursion-asideInfoTime {
  border: 1px solid #e6102d;
  border-radius: 4px;
  color: #e6102d;
  font-size: 20px;
  font-weight: 300;
  line-height: 34px;
  height: 34px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 60px;
}
.Excursion-faq {
  margin-top: 24px;
}
.Excursion-faq .FAQ-item {
  background: #fff;
  font-size: 16px;
  line-height: calc(24 / 16);
  padding: 10px;
}
.Excursion-faq .FAQ-item:first-of-type {
  border-top: 1px solid #edeef2;
}
.Excursion-faq .FAQ-item:last-of-type {
  border-bottom: 1px solid #edeef2;
}
.Excursion-faq .FAQ-item:hover:not(.isActive) {
  padding: 10px;
}
.Excursion-faq .FAQ-item.isActive {
  background: #e6102d;
  padding: 10px;
}
.Excursion-faq .FAQ-headerCross {
  align-self: flex-start;
  margin-top: 5px;
  height: 15px;
  width: 15px;
}
.Excursion-faq .FAQ-headerTitle {
  font-weight: 400;
  margin-right: 20px;
  transition: transform 0.3s ease;
}
.Excursion-faq .FAQ-answer {
  font-weight: 600;
}
.Excursion-fixedButton {
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 100%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1;
}
.Excursion-fixedButtonColumn {
  background: #fafafc;
  padding: 15px;
  position: relative;
}
.Excursion-fixedButtonColumn::before {
  background: linear-gradient(180deg, rgba(250, 250, 252, 0) 0%, #FAFAFC 99.99%, rgba(250, 250, 252, 0) 100%);
  content: "";
  position: absolute;
  height: 30px;
  top: -30px;
  left: 0;
  right: 0;
}
.Excursion-fixedButton.is-showed {
  opacity: 1;
  transform: translate(0, 0);
}
.Excursion-fixedButton .Excursion-button {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  padding: 0;
  height: 40px;
  width: 100%;
}
.Excursion-disclaimer {
  background: rgba(15, 12, 26, 0.7);
  display: none;
  position: fixed;
  padding: 15px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
}
.Excursion-disclaimer.is-showed {
  display: flex;
}
.Excursion-disclaimerClose {
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
}
.Excursion-disclaimerContent {
  background: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(24 / 16);
  padding: 30px 15px;
  position: relative;
  margin: auto;
  max-width: 806px;
}
.Excursion-disclaimerContentTitle {
  margin: 0;
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-weight: 500;
  font-size: 28px;
  line-height: calc(34 / 28);
}
.Excursion-disclaimerContentText {
  margin: 24px 0 0;
}
.Excursion-disclaimerContentText * + * {
  margin-top: 1em;
}
.Excursion-disclaimerContentText h3 {
  font-weight: 600;
  font-size: 18px;
}
.Excursion-disclaimerContentText a {
  color: #e6102d;
}
.FAQ-item {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 24px;
  position: relative;
  padding: 21px 27px 21px 23px;
  box-sizing: border-box;
  border-bottom: 1px solid #edeef2;
  transition: padding 0.15s ease-out, background 0.15s ease, color 0.15s ease, margin-bottom 0.15s ease, background-color 0.15s ease;
}
.FAQ-item:last-of-type {
  border-bottom: 1px solid transparent;
}
.FAQ-item:first-of-type {
  border-top: 1px solid transparent;
}
.FAQ-item:hover:not(.isActive) {
  color: #e6102d;
  padding: 21px 27px 21px 17px;
  background-color: white;
  transition: padding 0.15s ease-out, color 0.15s ease, background-color 0.15s ease;
  cursor: pointer;
}
.FAQ-item.isActive {
  color: #f7f8fc;
  padding: 21px 27px 21px 23px;
  background: #e6102d;
  transition: background 0.7s ease, color 0.15s ease, margin-bottom 0.15s ease;
}
.FAQ-header {
  display: flex;
  width: 100%;
}
.FAQ-headerTitle {
  position: relative;
  width: 100%;
}
.FAQ-headerCross {
  display: flex;
  align-self: center;
  height: 24px;
  width: 24px;
  transition: transform 0.15s ease;
  transform: rotate(45deg);
  z-index: 20;
  cursor: pointer;
}
.FAQ-headerCross svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  fill: red;
  transition: fill 0.7s ease;
}
.FAQ-headerCross.isActive {
  transition: transform 0.15s ease, opacity 0.3s ease;
  transform: rotate(0deg);
}
.FAQ-headerCross.isActive svg {
  fill: white;
  transition: fill 0.7s ease;
}
.FAQ-headerCross.isActive:hover {
  opacity: .7;
}
.FAQ-answer {
  display: none;
  transition: max-height 0.15s ease, color 0.15s ease;
}
.FAQ-answerContent {
  position: relative;
  border-bottom: 1px solid #ff4c64;
}
.FAQ-answerContent a {
  display: block;
  padding: 10px 0 11px 0;
}
.FAQ-answerContent:first-child {
  margin-top: 25px;
}
.FAQ-answerContent:last-child {
  border: none;
}
