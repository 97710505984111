.PopupAge {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
}
.PopupAge-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .8;
  background: #0f0c1a;
  z-index: 1;
}
.PopupAge-content {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  height: 271px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 42px;
  background: #fff;
  transition: transform 0.3s ease;
  transform: translateY(-20px);
  z-index: 2;
  border-radius: 2px;
  box-shadow: 0 30px 50px 0 rgba(0, 0, 0, 0.3);
}
.PopupAge-content.isVisible {
  transform: translateY(0);
}
@media (max-width: 374px) {
  .PopupAge-content {
    height: 260px;
  }
}
.PopupAge-logo {
  position: relative;
  height: 39px;
  width: 25px;
  fill: #e6102d;
}
.PopupAge-logo svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.PopupAge-question {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: #1c1630;
  margin-top: 35px;
}
@media (max-width: 374px) {
  .PopupAge-question {
    font-size: 26px;
    line-height: 30px;
  }
}
.PopupAge-controls {
  display: flex;
  margin-top: 32px;
}
.PopupAge-control {
  width: 165px;
}
@media (max-width: 374px) {
  .PopupAge-control {
    width: 135px;
  }
}
.PopupAge-control--accept {
  margin-right: 15px;
}
