.Production {
  height: 100%;
  background: #fafafc;
}
.Production-header {
  color: #e6102d;
  width: 100%;
  margin-bottom: -71px;
  padding: 133px 15px 0;
}
.Production-subtitle {
  display: flex;
  margin-bottom: 15px;
}
.Production-subtitleStroke {
  width: 110px;
  margin-right: 10px;
  border-bottom: 1px solid #e6102d;
}
.Production-subtitleText {
  font-size: 11px;
  font-weight: 500;
  line-height: 7px;
  letter-spacing: 2.75px;
  text-transform: uppercase;
  color: #e6102d;
}
.Production-description {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: #0f0c1a;
}
.Production-text {
  font-size: 20px;
  line-height: 32px;
  color: #0f0c1a;
}
.Production-aerialView {
  position: relative;
  padding-top: 142px;
  width: 100%;
  margin-bottom: 36px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.Production-aerialView-inner {
  position: relative;
  height: 400px;
}
.Production-aerialViewImg {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Production-aerialViewSvg {
  position: relative;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.Production-aerialViewPath {
  position: absolute;
  opacity: 0;
  fill: white;
  transition: opacity 0.3s ease 0.15s, fill 0.3s ease 0.15s;
}
.Production-aerialViewPath[state='foreground'] {
  opacity: .05;
  transition: opacity 0.3s ease, fill 0.3s ease;
}
.Production-aerialViewPath[state='background'] {
  opacity: .3;
  fill: black;
  transition: opacity 0.3s ease, fill 0.3s ease;
}
.Production .BuildingCross[data-id='1'] {
  top: 19.5%;
  left: 29%;
}
.Production .BuildingCross[data-id='2'] {
  top: 27%;
  left: 48%;
  z-index: 2;
}
.Production .BuildingCross[data-id='3'] {
  top: 22%;
  left: 62%;
}
.Production .BuildingCross[data-id='4'] {
  top: 28%;
  left: 76%;
}
.Production .BuildingCross[data-id='5'] {
  top: 18%;
  left: 86%;
}
.Production .BuildingCross[data-id='6'] {
  top: 33%;
  left: 92%;
}
.Production .BuildingCross[data-id='7'] {
  top: 41%;
  left: 128%;
}
.Production .BuildingCross[data-id='8'] {
  top: 90%;
  left: 148%;
}
.Production-contents {
  width: 100%;
}
.Production-contentsHeader {
  width: 100%;
  padding: 0 15px;
}
.Production-contentsStroke {
  content: '';
  height: 34px;
  width: 30px;
  border-top: 1px solid red;
}
.Production-contentsLeft {
  font-size: 16px;
  line-height: 25px;
  position: relative;
  width: 100%;
}
.Production-contentsLeft:after {
  height: 10px;
  width: 10px;
  background-color: red;
  border: 1px solid #e6102d;
}
.Production-contentsRight {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 22px;
  padding: 0 15px;
  padding-top: 23px;
}
.Production-contentsRightItem {
  width: 50%;
}
.Production-contentsRightItem:nth-child(2n) {
  margin-bottom: 58px;
  padding-left: 7px;
}
.Production-contentsRightItem:nth-child(3),
.Production-contentsRightItem:nth-child(4) {
  margin-bottom: 0;
}
.Production-contentsRightHeader {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 50px;
  line-height: 32px;
  color: #e6102d;
  margin-bottom: 11px;
}
.Production-contentsRightSpan {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 18px;
}
.Production-contentsRightDescription {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-top: 1px;
  padding-left: 1px;
}
.Production-contentsSlider {
  margin-bottom: 124px;
}
.Production-contentsText {
  font-size: 16px;
  line-height: 25px;
  color: #1c1630;
  margin-bottom: 26px;
  padding: 0 15px;
}
.Production-icon {
  display: flex;
  position: relative;
  margin-bottom: 31px;
  padding: 0 15px;
  padding-top: 16px;
}
.Production-icon svg {
  height: 80px;
  width: 80px;
  top: 0;
  left: 0;
}
.Production-iconSvg {
  margin-right: 31px;
}
.Production-iconText {
  font-size: 14px;
  line-height: 20px;
  color: #78797a;
  padding-top: 4px;
}
.Production-media {
  position: relative;
  align-items: center;
  height: 400px;
  width: calc(100vw - 30px);
  margin: 0 15px 15px;
  overflow: hidden;
}
.Production-mediaVideo {
  font-family: 'object-fit: cover; object-position: center';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.Production-mediaTexts {
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  padding: 0;
}
.Production-mediaTitle {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-align: center;
  color: #1c1630;
  width: 100%;
  margin-top: 144px;
  margin-bottom: 5px;
}
.Production-mediaSubtitle {
  font-family: 'Graphik LCG', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: center;
  color: #78797a;
  width: 100%;
}
