.PostItem {
  position: relative;
  width: calc(100% - 30px);
  margin-bottom: 15px;
  cursor: pointer;
}
.PostItem:after {
  content: '';
  display: block;
  padding-bottom: 108.32%;
}
.PostItem-link {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.PostItem-link:hover .PostItem-title,
.PostItem-link:focus .PostItem-title {
  border-color: transparent !important;
}
.PostItem-link:hover .PostItem-img:after,
.PostItem-link:focus .PostItem-img:after {
  opacity: 1;
}
.PostItem-link:hover + .PostItem-overlay,
.PostItem-link:focus + .PostItem-overlay {
  opacity: 1;
}
.PostItem-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 56px;
  padding-left: 16px;
  padding-right: 15px;
  z-index: 6;
}
.PostItem-meta {
  font-size: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 17px;
  padding-top: 24px;
  padding-left: 17px;
  z-index: 10;
}
.PostItem-category {
  margin-right: 11px;
}
.PostItem-date {
  font-size: 11px;
  line-height: 18px;
  letter-spacing: .85px;
  text-transform: uppercase;
  height: 16px;
  vertical-align: top;
}
.PostItem-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 10px;
  padding-bottom: 1px;
  border-bottom: 1px solid;
  transition: border-color 0.3s ease;
}
.PostItem-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.PostItem-img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.PostItem-imgOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.PostItem-viewCount {
  position: absolute;
  bottom: 9px;
  right: 7px;
  z-index: 6;
}
.PostItem--index {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
}
.PostItem--index:after {
  content: normal;
  padding-bottom: 0;
}
.PostItem--index .PostItem-meta {
  padding-top: 24px;
}
.PostItem--index .PostItem-category {
  margin-right: 12px;
}
.PostItem--index .PostItem-content {
  padding-top: 57px;
}
.PostItem--index .PostItem-title {
  font-family: 'BerlingskeSerifCn-Md', Times, serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 10px;
  padding-bottom: 1px;
  border-bottom: 1px solid;
  transition: border-color 0.3s ease;
}
.PostItem-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(28, 22, 48, 0.2);
  transition: opacity 0.3s ease;
  z-index: 11;
  pointer-events: none;
}
.PostItem--post {
  flex-shrink: 0;
  width: calc(100vw - 55px);
  margin-bottom: 0;
  margin-right: 10px;
}
.PostItem--post:after {
  content: '';
  display: block;
  padding-bottom: 109.92%;
}
